import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import qs from "query-string";

import ROUTES from "../../routes";
import {
  Box,
  BoxSeparator,
  Confirm,
  ContentBox,
  EmptyMessage,
  ForbiddenSection,
  OperationsTable,
  Page,
  TitleBox,
} from "../../components";
import { user as userActions } from "../../actions";
import { Colors, Language } from "../../utils";

import ListItemText from "@material-ui/core/ListItemText";
import Table from "@material-ui/core/Table";
import {
  Add as AddIcon,
  Visibility as VisisbilityIcon,
  Delete as DeleteIcon,
  LockOpen as LockOpenIcon,
  Block as BlockIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from "@material-ui/icons";
import MoreVerticalIcon from "../../static/images/icons/more_vertical.svg";

import makeClasses from "./styles";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { Alert, AlertTitle, Pagination } from "@material-ui/lab";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Policies } from "../../utils/Policies";
import { checkForExpiredSession } from "../../utils/Utils";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: Colors.rgb.grey,
    },
    "&:nth-of-type(even)": {
      backgroundColor: Colors.rgb.white,
    },
  },
}))(TableRow);

const ManageUsersScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();
  const searchParams = qs.parse(history.location.search);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [users, setUsers] = useState({});
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(-1);
  const [showDeleteUSerDialog, setShowDeleteUserDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [paginationPage, setPaginationPage] = useState(
    searchParams.page ? parseInt(searchParams.page) : 1
  );
  const [forbiddenSection, setForbbidenSection] = useState(false);
  const [usersCount, setUsersCount] = useState(null);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const { policies, authTime, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    authTime: state.user.userData.authTime || Date.now(),
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const hasUsers = users.elements && users.elements.length > 0;
  const canModifyUsers =
    policies && policies.includes(Policies.types.MANAGE_USERS_WRITE);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (policies && policies.includes(Policies.types.MANAGE_USERS)) {
      initialize();
    } else {
      setForbbidenSection(true);
    }
  }, [policies, paginationPage]);

  const initialize = () => {
    loadUsers();

    if (policies.includes(Policies.types.PROFILE)) {
      loadProfile();
    }
  };

  const loadUsers = () => {
    dispatch(
      userActions.getUsers(paginationPage, (users) => {
        setUsers(users);
      })
    );
  };

  const loadProfile = () => {
    dispatch(
      userActions.getUsersCount((count) => {
        setUsersCount(count);
      })
    );
  };

  const handleOnClickNewUser = () => {
    const exiredSession = checkForExpiredSession(authTime);

    if (exiredSession) {
      return toggleSessionExpiredModal();
    }

    dispatch(push(ROUTES.MANAGE_USERS_ADD.path));
  };

  const toggleMenu = (index) => (e) => {
    const exiredSession = checkForExpiredSession(authTime);

    if (exiredSession) {
      return toggleSessionExpiredModal();
    }

    if (menuAnchorEl) {
      setMenuAnchorEl(null);
      setMenuOpenIndex(-1);
    } else {
      setMenuAnchorEl(e.target);
      setMenuOpenIndex(index);
    }
  };

  const handleOnEditUser = (user) => () => {
    toggleMenu()();
    dispatch(
      push(ROUTES.MANAGE_USERS_EDIT.path.replace(":traceId", user.traceId))
    );
  };

  const handleOnChangeUserPassword = (user) => () => {
    toggleMenu()();
    dispatch(
      push(
        ROUTES.MANAGE_USERS_CHANGE_PASSWORD.path.replace(
          ":traceId",
          user.traceId
        )
      )
    );
  };

  const handleOnToggleUserStatus = (user) => () => {
    toggleMenu()();

    if (user.status === "ACTIVE") {
      dispatch(
        userActions.disableUser(user.traceId, () => {
          toast.success(i18n.get("ManageUsers.SuccessDisableMessage"));
          loadUsers();
        })
      );
    } else if (user.status === "BLOCKED") {
      dispatch(
        userActions.enableUser(user.traceId, () => {
          toast.success(i18n.get("ManageUsers.SuccessEnableMessage"));
          loadUsers();
        })
      );
    }
  };

  const handleOnDeleteUser = (user) => () => {
    toggleMenu()();
    toggleDeleteUserDialog();
    setUserToDelete(user.traceId);
  };

  const toggleDeleteUserDialog = () => {
    setShowDeleteUserDialog(!showDeleteUSerDialog);
  };

  const toggleSessionExpiredModal = () => {
    setShowSessionExpiredModal(!showSessionExpiredModal);
  };

  const confirmDeleteUser = () => {
    toggleDeleteUserDialog();
    dispatch(
      userActions.deleteUser(userToDelete, () => {
        toast.success(i18n.get("ManageUsers.SuccessDeleteMessage"));
        initialize();
      })
    );
  };

  const handleOnPaginationChange = (nextPage) => {
    setPaginationPage(nextPage);

    const searchParams = qs.parse(history.location.search) || {};
    searchParams.page = nextPage;

    dispatch(
      push({
        search: qs.stringify(searchParams),
      })
    );
  };

  const handleLogOut = () => {
    dispatch(userActions.logOut());
  };

  const renderUsersCount = () => {
    if (!usersCount) {
      return null;
    }

    return (
      <div className={classes.usersCountContainer}>
        <div className={classes.usersCountTitle}>
          {i18n.get("ManageUsers.UsersTableUsersCountTitle")}
        </div>
        <div className={classes.usersCountItemsWrapper}>
          <div className={classes.usersCountItem}>
            {i18n.get("ManageUsers.UsersTableUsersCountMaximum")}{" "}
            <div className={classes.usersCountItemCount}>
              {usersCount.maximumUserCount}
            </div>
          </div>
          <div
            className={clsx(classes.usersCountItem, classes.usersCountItemLast)}
          >
            {i18n.get("ManageUsers.UsersTableUsersCountAvailable")}
            <div className={classes.usersCountItemCount}>
              {usersCount.maximumUserCount - usersCount.currentUserCount}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderUsers = () => {
    /*if (isSmallScreen) {
      return (
        <div>
          {users.elements.map((user, index) => (
            <React.Fragment key={index}>
              {index === 0 ? null : (
                <div className={classes.boxItemSeparator} />
              )}
              <div
                className={clsx(
                  classes.boxContainer,
                  index % 2 === 0 ? classes.boxContainerEven : null
                )}
              >
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>
                    {i18n.get("ManageUsers.UsersTableHeaderUsername")}:
                  </span>
                  <span>{user.username}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get("ManageUsers.UsersTableHeaderName")}:</span>
                  <span>{user.name}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get("ManageUsers.UsersTableHeaderRole")}:</span>
                  <span>{user.role.name}</span>
                </div>
                <div className={clsx(classes.boxItem, classes.boxItemCompact)}>
                  <span>{i18n.get("ManageUsers.UsersTableHeaderStatus")}:</span>
                  <span
                    className={clsx(
                      classes.tableBodyCellStatus,
                      user.status === "ACTIVE"
                        ? classes.tableBodyCellStatusActive
                        : user.status === "BLOCKED"
                        ? classes.tableBodyCellStatusDisabled
                        : null
                    )}
                  >
                    {user.status === "ACTIVE"
                      ? i18n.get("ManageUsers.UsersTableBodyStatusActive")
                      : user.status === "BLOCKED"
                      ? i18n.get("ManageUsers.UsersTableBodyStatusDisabled")
                      : null}
                  </span>
                </div>
                <div className={classes.listItemActions}>
                  <IconButton onClick={toggleMenu(index)}>
                    <img src={MoreVerticalIcon} alt="More" />
                  </IconButton>
                </div>
                <Menu
                  anchorEl={menuAnchorEl}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={menuOpenIndex === index}
                  onClose={toggleMenu()}
                  className={classes.listItemMenu}
                >
                  <MenuItem
                    onClick={handleOnEditUser(user)}
                    className={classes.listItemMenuItem}
                  >
                    <ListItemIcon className={classes.menuItemIcon}>
                      <VisisbilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        canModifyUsers
                          ? i18n.get(
                              "ManageUsers.UsersTableBodyActionsViewEditUser"
                            )
                          : i18n.get(
                              "ManageUsers.UsersTableBodyActionsViewUser"
                            )
                      }
                      className={classes.menuItemText}
                    />
                  </MenuItem>
                  {canModifyUsers ? (
                    <MenuItem
                      onClick={handleOnChangeUserPassword(user)}
                      className={classes.listItemMenuItem}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        <LockOpenIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get(
                          "ManageUsers.UsersTableBodyActionsChangeUserPassword"
                        )}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                  {canModifyUsers ? (
                    <MenuItem
                      onClick={handleOnToggleUserStatus(user)}
                      className={classes.listItemMenuItem}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        {user.status === "ACTIVE" ? (
                          <BlockIcon fontSize="small" />
                        ) : (
                          <CheckCircleOutlineIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          user.status === "ACTIVE"
                            ? i18n.get(
                                "ManageUsers.UsersTableBodyActionsDisableUser"
                              )
                            : i18n.get(
                                "ManageUsers.UsersTableBodyActionsEnableUser"
                              )
                        }
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                  {canModifyUsers ? (
                    <MenuItem
                      onClick={handleOnDeleteUser(user)}
                      className={classes.listItemMenuItem}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.get(
                          "ManageUsers.UsersTableBodyActionsDeleteUser"
                        )}
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }*/

    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get("ManageUsers.UsersTableHeaderUsername"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get("ManageUsers.UsersTableHeaderName"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 3,
        name: i18n.get("ManageUsers.UsersTableHeaderRole"),
        sortable: false,
        type: "text",
      },
      {
        canHide: false,
        columnNumber: 4,
        name: i18n.get("ManageUsers.UsersTableHeaderStatus"),
        sortable: false,
        type: "status",
      },
      {
        canHide: false,
        columnNumber: 5,
        name: i18n.get("ManageUsers.UsersTableHeaderActions"),
        sortable: false,
        type: "custom",
        align:'right',
        show: !isSmallScreen,
      },
    ];

    const body = (users?.elements || []).map((item, index) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.username,
        },
        {
          columnNumber: 2,
          data: item.name,
        },
        {
          columnNumber: 3,
          data: item.role.name,
        },
        {
          columnNumber: 4,
          data: item.status,
        },
        {
          columnNumber: 5,
          data: () => {
            return (
              <div className={classes.listItemActions}>
                <IconButton onClick={toggleMenu(index)}>
                  <img src={MoreVerticalIcon} alt="More" />
                </IconButton>
                <Menu
                    anchorEl={menuAnchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={menuOpenIndex === index}
                    onClose={toggleMenu()}
                    className={classes.listItemMenu}
                  >
                    <MenuItem
                      onClick={handleOnEditUser(item)}
                      className={classes.listItemMenuItem}
                    >
                      <ListItemIcon className={classes.menuItemIcon}>
                        <VisisbilityIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          canModifyUsers
                            ? i18n.get(
                                "ManageUsers.UsersTableBodyActionsViewEditUser"
                              )
                            : i18n.get(
                                "ManageUsers.UsersTableBodyActionsViewUser"
                              )
                        }
                        className={classes.menuItemText}
                      />
                    </MenuItem>
                    {canModifyUsers ? (
                      <MenuItem
                        onClick={handleOnChangeUserPassword(item)}
                        className={classes.listItemMenuItem}
                      >
                        <ListItemIcon className={classes.menuItemIcon}>
                          <LockOpenIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.get(
                            "ManageUsers.UsersTableBodyActionsChangeUserPassword"
                          )}
                          className={classes.menuItemText}
                        />
                      </MenuItem>
                    ) : null}
                    {canModifyUsers ? (
                      <MenuItem
                        onClick={handleOnToggleUserStatus(item)}
                        className={classes.listItemMenuItem}
                      >
                        <ListItemIcon className={classes.menuItemIcon}>
                          {item.status === "ACTIVE" ? (
                            <BlockIcon fontSize="small" />
                          ) : (
                            <CheckCircleOutlineIcon fontSize="small" />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            item.status === "ACTIVE"
                              ? i18n.get(
                                  "ManageUsers.UsersTableBodyActionsDisableUser"
                                )
                              : i18n.get(
                                  "ManageUsers.UsersTableBodyActionsEnableUser"
                                )
                          }
                          className={classes.menuItemText}
                        />
                      </MenuItem>
                    ) : null}
                    {canModifyUsers ? (
                      <MenuItem
                        onClick={handleOnDeleteUser(item)}
                        className={classes.listItemMenuItem}
                      >
                        <ListItemIcon className={classes.menuItemIcon}>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.get(
                            "ManageUsers.UsersTableBodyActionsDeleteUser"
                          )}
                          className={classes.menuItemText}
                        />
                      </MenuItem>
                    ) : null}
                  </Menu>
              </div>
            );
          },
        },
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        currentPage={paginationPage}
        paginationSimple
        hasMorePages={users.hasNext}
        onPaginationChange={handleOnPaginationChange}
        emptyMessage={i18n.get(
          "ManageUsers.GridEmptyMessage"
        )}
        showDetails={false}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get("ManageUsers.Title")}
        withActivePage={ROUTES.MANAGE_USERS.id}
        withBanner={false}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get("ManageUsers.Title")}
      withActivePage={ROUTES.MANAGE_USERS.id}
    >
      <TitleBox
        title={i18n.get("ManageUsers.BoxTitle")}
        buttonRight={canModifyUsers}
        buttonRightIcon={() => <AddIcon fontSize="small" />}
        buttonRightText={
          isSmallScreen ? null : i18n.get("ManageUsers.BoxTitleButtonText")
        }
        buttonRightOnClick={handleOnClickNewUser}
      />
      <BoxSeparator />
      {hasUsers ? (
        <ContentBox
          title={i18n.get("ManageUsers.BoxUsersTitle")}
          titleBold
          headerVertical={isSmallScreen}
          customRightAction={renderUsersCount}
        >
          {renderUsers()}
        </ContentBox>
      ) : (
        <Box>
          <div className={classes.noDataMessage}>
            {i18n.get("ManageUsers.NoUsersMessage")}
          </div>
        </Box>
      )}
      <Confirm
        open={showDeleteUSerDialog}
        onClose={toggleDeleteUserDialog}
        title={i18n.get("ManageUsers.DeleteUserDialogTitle")}
        confirmText={i18n.get("ManageUsers.DeleteUserDialogActionConfirm")}
        onConfirm={confirmDeleteUser}
        cancelText={i18n.get("ManageUsers.DeleteUserDialogActionCancel")}
        onCancel={toggleDeleteUserDialog}
      >
        <Alert
          severity="error"
          icon={false}
          className={clsx(classes.alert, classes.alertDanger)}
        >
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get("ManageUsers.DeleteUserDialogContentTitle")}
            </AlertTitle>
            {i18n.get("ManageUsers.DeleteUserDialogContentText")}
          </div>
        </Alert>
      </Confirm>
      <Confirm
        open={showSessionExpiredModal}
        onClose={toggleSessionExpiredModal}
        title={i18n.get("ManageUsers.ExpiredSessionDialogActionTitle")}
        onConfirm={handleLogOut}
        confirmText={i18n.get("ManageUsers.ExpiredSessionDialogActionConfirm")}
        cancelText={i18n.get("ManageUsers.ExpiredSessionDialogActionCancel")}
        onCancel={toggleSessionExpiredModal}
      >
        <Alert
          severity="info"
          icon={false}
          className={clsx(classes.alert, classes.alertInfo)}
        >
          <div>
            <InfoOutlinedIcon className={classes.alertIcon} />
          </div>
          <div className={classes.alertMessage}>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get("ManageUsers.ExpiredSessionDialogContentTitle")}
            </AlertTitle>
            {i18n.get("ManageUsers.ExpiredSessionDialogContentMessage")}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

ManageUsersScreen.id = "com.Handy.ManageUsers";

export default ManageUsersScreen;
