import { all, takeEvery, select, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import qs from 'query-string';
import _ from 'lodash';

import { businessTypes, business as businessActions } from '../actions';
import { Language, API } from '../utils';
import { API_PATHS, API_RESPONSES } from '../utils/Api';
import ROUTES from '../routes';
import { Policies } from '../utils/Policies';

const qsConfig = {
  skipNull: true,
  skipEmptyString: true,
  encode: true,
};

async function handleSuccess(message) {
  const i18n = Language();

  if (message && typeof message === 'string') {
    toast.success(i18n.get(message, false) || message);
  } else {
    toast.success(i18n.get('Success'));
  }
}

async function handleError(error) {
  const i18n = Language();
  if (error && typeof error.message === 'string') {
    toast.error(error.message);
  } else {
    toast.error(i18n.get('Error'));
  }
}

function* initialize() {
  try {
    const policies = yield select(({ user }) => user.userData.policies || []);
    const checks = [];

    if (policies.includes(Policies.types.BRANCHES)) {
      checks.push(call(getBranches));
      checks.push(call(getBranchesWithNoPaymentMethod));
    }
    if (policies.includes(Policies.types.NOTIFICATIONS)) {
      checks.push(call(getNotifications));
    }
    if (policies.includes(Policies.types.LOANS_WRITE)) {
      checks.push(call(checkLoansAvailability));
    }

    yield all(checks);
  } catch (e) {
    console.log(e);
  }
}

function* getBranches() {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const profileResult = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).PROFILE.BRANCHES());

    if (profileResult.status === API_RESPONSES.SUCCESS) {
      yield put(businessActions.setBranches(profileResult.data));
    } else {
      throw profileResult.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getBranchAddress({ branchTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).PROFILE.BRANCH_ADDRESS(branchTraceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getBranchesWithNoPaymentMethod() {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_PAYMENT_METHODS.GET_BRANCHES_WITH_NO_PAYMENT_METHOD()
    );

    if (result.status === 'success') {
      yield put(businessActions.setBranchesWithNoPaymentMethod(result.data.filter((b) => b.currencies.length === 0)));
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getAccounts({ page, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        accountType: 18,
        page,
        count: 10,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).ACCOUNTS());

    if (result.status === API_RESPONSES.SUCCESS) {
      const accounts = _.flatten(
        result.data.map((a) =>
          a.accounts.elements ? a.accounts.elements.map((e) => ({ ...e, branch: a.branch })) : []
        )
      );
      callback(accounts);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getAccountDetails({ accountTraceId, startDate, endDate, sign, page, view, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        page,
        count: 10,
        sign,
        start: startDate,
        end: endDate,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).ACCOUNT_DETAILS(accountTraceId, view));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getSummary({ branchId, columnOrder, columnOrderType }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        columnOrder: columnOrder ? `column${columnOrder}` : null,
        asc: columnOrderType === 'asc' ? true : null,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).SUMMARIES(branchId));

    if (result.status === API_RESPONSES.SUCCESS) {
      yield put(businessActions.setSummary(result.data));
    } else {
      yield put(businessActions.setSummary({}));
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getPaymentSchedule({ branchId, month, year }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        month,
        year,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).CALENDARIES(branchId));

    if (result.status === API_RESPONSES.SUCCESS) {
      yield put(businessActions.setPaymentSchedule(result.data));
    } else {
      yield put(businessActions.setPaymentSchedule({}));
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getPaymentDetailsByDay({ branchId, start, end, page, columnOrder, columnOrderType, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        start,
        end,
        page,
        columnOrder: columnOrder ? (columnOrder ? `column${columnOrder}` : null) : null,
        asc: columnOrderType === 'asc' ? true : null,
        count: 10,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).INVOICES(branchId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getOperationDetails({ operationId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).INVOICES_BY_INVOICE_ID(operationId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getActivity({ params }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        start: params.start,
        end: params.end,
        page: params.page,
        count: 15,
        columnOrder: params.columnOrder ? `column${params.columnOrder}` : null,
        asc: params.columnOrderType === 'asc' ? true : null,
        networkIdentifier: params.networkIdentifier,
        networkKey: params.networkKey,
        brands: params.acquirer,
        currency: params.currency,
        movementType: params.movementType,
        amount: params.amount,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).ACTIVITIES(params.branchId));

    if (result.status === API_RESPONSES.SUCCESS) {
      yield put(businessActions.setActivity(result.data));
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getReversals({ branchId, startDate, endDate, page, columnOrder, columnOrderType }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        start: startDate,
        end: endDate,
        page,
        columnOrder: columnOrder ? `column${columnOrder}` : null,
        asc: columnOrderType === 'asc' ? true : null,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).REVERSALS(branchId));

    if (result.status === API_RESPONSES.SUCCESS) {
      yield put(businessActions.setReversals(result.data));
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* setPaymentPlan({ paymentPlan }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().MERCHANTS(merchantTraceId).PLANS(paymentPlan));

    if (result.status === 'error') {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getVouchers({
  branchId,
  startDate,
  endDate,
  voucherType,
  page,
  count,
  columnOrder,
  columnOrderType,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        branchId,
        begin: startDate,
        end: endDate,
        type: voucherType,
        page,
        count,
        columnOrder: columnOrder ? `column${columnOrder}` : null,
        asc: columnOrderType === 'asc' ? true : null,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).VOUCHERS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getHistory({ branchId, startDate, endDate, page = 1, count = 15, columnOrder, columnOrderType }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        start: startDate,
        end: endDate,
        page,
        count,
        columnOrder: columnOrder ? `column${columnOrder}` : null,
        asc: columnOrderType === 'asc' ? true : null,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).PAYMENTS(branchId));

    if (result.status === API_RESPONSES.SUCCESS) {
      yield put(businessActions.setHistory(result.data));
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getLoans() {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).LOANS());

    if (result.status === API_RESPONSES.SUCCESS) {
      yield put(businessActions.setLoans(result.data.loans));
    } else {
      throw result.message;
    }
  } catch (e) {}

  yield put(businessActions.setLoading(false));
}

function* checkLoansAvailability() {
  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).CHECK_LOANS_AVAILABILITY());

    if (result.status === API_RESPONSES.SUCCESS) {
      yield put(businessActions.setLoanAvailability(result.data));
    } else {
      yield put(businessActions.setLoanAvailability({ offerState: 'NO_OFFER' }));
      throw result.message;
    }
  } catch (e) {}
}

function* getLoanLink({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).LOAN_REDIRECT_LINK());

    if (result.status === API_RESPONSES.SUCCESS) {
      yield callback(result.data.redirectLink);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* exportTable({ exportType, params, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const searchParams = {};
    const traceId = params.traceId || null;
    delete params.traceId;

    for (let i in params) {
      if (params[i] === 'columnOrderType') {
        searchParams.asc = params[i] === 'asc';
      } else if (params[i] === 'columnOrder') {
        searchParams.columnOrder = `column${params[i]}`;
      } else {
        searchParams[i] = params[i];
      }
    }

    const search = qs.stringify(searchParams, qsConfig);
    let entity = '';

    if (exportType === 'activity') {
      entity = `activities`;
    } else if (exportType === 'history') {
      entity = `payments`;
    } else if (exportType === 'dayDetails') {
      entity = `sales`;
    } else if (exportType === 'reversals') {
      entity = `reversals`;
    } else if (exportType === 'vouchers') {
      entity = `vouchers`;
    } else if (exportType === 'account-details') {
      entity = `account-details`;
    }

    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).EXPORT(entity, traceId), {
      file: true,
    });

    if (result.status === API_RESPONSES.SUCCESS && typeof callback === 'function') {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getSecrets({ returnSecrets, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).SECRETS());

    if (returnSecrets) {
      callback(result.data);
    } else {
      if (Array.isArray(result.data) && result.data.length > 0) {
        callback(true);
      } else {
        callback(false);
      }
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getBranchSecret({ traceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).SECRETS(traceId));

    if (result.status === API_RESPONSES.SUCCESS && typeof callback === 'function') {
      if (result.data && result.data.length > 0) {
        const branchQr = result.data.find((i) => i.branchTraceId === traceId);
        callback(branchQr ? branchQr.secret : null);
      } else {
        callback(null);
      }
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getPaymentLinks({ status, page, searchQuery }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        status,
        page,
        title: searchQuery,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).LINKS());

    if (result.status === API_RESPONSES.SUCCESS) {
      const paymentLinks = {
        hasNext: result.data.hasNext,
        count: result.data.count,
        links: _.orderBy(result.data.elements, ['linkCreationDate'], ['desc']),
      };
      yield put(businessActions.setPaymentLinks(paymentLinks));
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* createPaymentLink({ branchSecret, params, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const secret = qs.stringify(
      {
        secret: branchSecret,
      },
      qsConfig
    );
    const result = yield API.post(API_PATHS(secret).MERCHANTS(merchantTraceId).LINKS(), params);

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data.traceId);
    } else {
      throw result.message;
    }
  } catch (e) {
    const i18n = Language();
    if (e && e.Message === 'The branch status is not active') {
      handleError({ message: i18n.get('PaymentLink.ErrorBranchStatusNotActive') }, 15000);
    } else {
      handleError({ message: e });
    }
  }

  yield put(businessActions.setLoading(false));
}

function* deletePaymentLink({ traceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.delete(API_PATHS().MERCHANTS(merchantTraceId).LINKS(traceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      handleSuccess('SuccessPaymentLinkDelete');
      if (typeof callback === 'function') {
        callback();
      }
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getPaymentLink({ traceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).LINKS(traceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getPaymentLinkTransactions({ traceId, startDate, endDate, page, pageSize, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        page,
        beginDate: startDate,
        endDate,
        count: pageSize,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).LINK_TRANSACTIONS(traceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getPaymentLinkRestrictions({ traceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).LINK_RESTRICTIONS(traceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* exportPaymentLinks({ status, searchQuery, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        status,
        title: searchQuery,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).LINKS_EXPORT(), {
      file: true,
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getNotifications() {
  try {
    // const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    // const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).NOTIFICATIONS());

    // if (result.status === API_RESPONSES.SUCCESS) {
    //   if (result.data && result.data.length > 0) {
    //     yield put(businessActions.setNotifications(result.data));
    //   } else {
    //     yield put(businessActions.setNotifications([]));
    //   }
    // } else {
    //   throw result.message;
    // }
    
    yield put(businessActions.setNotifications([{
      message: '¡Llegaron las recargas de celulares a tu POS! Activá este nuevo servicio llamando al tel. 2622 4343, de lunes a viernes de 8 a 20 h. Si sos usuario PRO, te exoneramos el costo de mantenimiento.',
      type: 'rut'
    }]));
  } catch (e) {
    handleError({ message: e });
  }
}

function* deleteNotification({ notificationId }) {
  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.delete(API_PATHS().MERCHANTS(merchantTraceId).NOTIFICATIONS(notificationId));

    if (result.status === API_RESPONSES.SUCCESS) {
      yield put(businessActions.removeNotification(notificationId));
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }
}

function* getMobileBalanceStatus({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).MOBILE_BALANCE_STATUS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data.isActive);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* checkForActiveServices({ ids, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const servicesStatus = ids.reduce((acc, route) => {
      acc[route] = false;
      return acc;
    }, {});
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    for (const routeKey in servicesStatus) {
      if (routeKey === ROUTES.MOBILE_BALANCE.id) {
        const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).MOBILE_BALANCE_STATUS());
        if (result.status === API_RESPONSES.SUCCESS) {
          servicesStatus[ids[routeKey]] = result.data.isActive;
        }
      }
  
      if (routeKey === ROUTES.ITAU.id) {
        const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).ITAU_BRANCHES());
        if (result.status === API_RESPONSES.SUCCESS) {
          servicesStatus[routeKey] = result.data && result.data.length > 0;
        }
      }
  
      if (routeKey === ROUTES.MANAGE_PROVIDERS.id) {
        const providersResult = yield API.get(API_PATHS().PROVIDERS(merchantTraceId).ME());
        if (providersResult.status === API_RESPONSES.SUCCESS && providersResult.data.isActive) {
          servicesStatus[routeKey] = true;
        }
      }
  
      if (routeKey === ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id) {
        const providersSubscriptionsResult = yield API.get(API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).GET());
        if (providersSubscriptionsResult.status === API_RESPONSES.SUCCESS && providersSubscriptionsResult.data.isActive) {
          servicesStatus[ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id] = true;
        }
      }
  
      if (routeKey === ROUTES.ECOMMERCE.id) {
        const additionalServicesResult = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).PROFILE.ADDITIONAL_SERVICES('Tienda Ecommerce (Rhino)'));
        if (additionalServicesResult.status === API_RESPONSES.SUCCESS && additionalServicesResult.data.active) {
          servicesStatus[ROUTES.ECOMMERCE.id] = additionalServicesResult.data.additionalData;
        }
      }
    }

    callback(servicesStatus);
  } catch (e) {
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getMobileBalance({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).MOBILE_BALANCE());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getMobileBalanceActivePos({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        page: 1,
        count: 10,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MOBILE_BALANCE_ACTIVE_POS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getMobileBalanceHistory({
  startDate,
  endDate,
  phoneCompany,
  pos,
  amountSearch,
  phoneDigitsSearch,
  page,
  pageSize,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        dateFrom: startDate,
        dateTo: endDate,
        phoneCompany,
        posTraceId: pos,
        rechargeAmount: amountSearch,
        phoneMask: phoneDigitsSearch,
        page,
        count: pageSize,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MOBILE_BALANCE_HISTORY());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getPosAliases({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).POS_ALIASES());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getPhoneCompanies({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().PHONE_COMPANIES());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getItauBranches({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).ITAU_BRANCHES());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getBranchIssuers({ branchId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).BRANCH_ISSUERS(branchId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* getItauSales({ branchId, startDate, endDate, page, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const search = qs.stringify(
      {
        branchTraceId: branchId,
        begin: startDate,
        end: endDate,
        page,
      },
      qsConfig
    );
    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).ITAU_SALES());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
  }

  yield put(businessActions.setLoading(false));
}

function* makeItauSale({ traceId, form, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.post(API_PATHS().MERCHANTS(merchantTraceId).ITAU_SALE(traceId), {
      transactionContext: {
        amount: parseFloat(form.amount),
        taxedAmount: parseFloat(form.taxedAmount),
        installments: 24,
        tip: 0,
        currencyCode: form.currency,
        billNumber: form.invoiceNumber,
      },
      customerContext: {
        documentType: 'IDENTITY_CARD',
        document: form.legalId.replace(/\.|-/g, ''),
        clientAdditionalFields: {
          fields: {
            CardLastFourDigits: form.last4.substring(form.last4.length - 4, form.last4.length),
          },
        },
      },
      paymentMethodContext: {
        name: 'ITAU_FINANCING',
        type: 'BNPL',
        placeOfSale: 'CASHER',
      },
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data.status === 'OK' ? 1 : result.data.status === 'PENDING' ? 3 : 2, result.data.message || null);
    } else {
      throw result.message.Message;
    }
  } catch (e) {
    callback(2, e);
  }

  yield put(businessActions.setLoading(false));
}

function* getTransactionsForHome({ startDate, endDate, callback }) {
  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        start: startDate,
        end: endDate,
        page: 1,
        count: 5,
        columnOrder: null,
        asc: null,
      },
      qsConfig
    );

    const transactionsResult = yield API.get(API_PATHS(search).HOME(merchantTraceId).TRANSACTIONS());

    if (transactionsResult.status === API_RESPONSES.SUCCESS) {
      callback(transactionsResult.data);
    } else {
      callback([]);
    }
  } catch (e) {
    handleError({ message: e });
  }
}

function* getHistoryForHome({ startDate, endDate, callback }) {
  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        start: startDate,
        end: endDate,
        page: 1,
        count: 5,
        columnOrder: null,
        asc: null,
      },
      qsConfig
    );

    const paymentsResult = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).PAYMENTS());

    if (paymentsResult.status === API_RESPONSES.SUCCESS) {
      callback(paymentsResult.data);
    } else {
      callback([]);
    }
  } catch (e) {
    handleError({ message: e });
  }
}

function* getBalancesForHome({ todayDate, callback }) {
  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const baseParams = {
      today: todayDate,
    };
    const balanceSearch = qs.stringify(baseParams, qsConfig);
    const pctParams = { ...baseParams, brand: 'TOKE' };
    const pctBalanceSearch = qs.stringify(pctParams, qsConfig);

    const [
      balanceResult,
      pendingBalanceResult,
      purchaseTodayBalanceResult,
      pctPurchaseTodayBalanceResult,
      tipsTodayBalanceResult,
    ] = yield all([
      API.get(API_PATHS().HOME(merchantTraceId).BALANCE()),
      API.get(API_PATHS(balanceSearch).HOME(merchantTraceId).PENDING_BALANCE()),
      API.get(API_PATHS(balanceSearch).HOME(merchantTraceId).PURCHASE_TODAY_BALANCE()),
      API.get(API_PATHS(pctBalanceSearch).HOME(merchantTraceId).PURCHASE_TODAY_BALANCE()),
      API.get(API_PATHS(balanceSearch).HOME(merchantTraceId).TIPS_TODAY_BALANCE()),
    ]);

    const result = {
      balance: null,
      pendingBalance: null,
      purchaseTodayBalance: null,
      tcpPurchaseTodayBalance: null,
      tipsTodayBalance: null,
    };

    if (balanceResult.status === API_RESPONSES.SUCCESS) {
      result.balance = balanceResult.data;
    }
    if (pendingBalanceResult.status === API_RESPONSES.SUCCESS) {
      result.pendingBalance = pendingBalanceResult.data;
    }
    if (purchaseTodayBalanceResult.status === API_RESPONSES.SUCCESS) {
      result.purchaseTodayBalance = purchaseTodayBalanceResult.data;
    }
    if (pctPurchaseTodayBalanceResult.status === API_RESPONSES.SUCCESS && pctPurchaseTodayBalanceResult.data.length > 0) {
      result.tcpPurchaseTodayBalance = pctPurchaseTodayBalanceResult.data;
    }
    if (tipsTodayBalanceResult.status === API_RESPONSES.SUCCESS) {
      result.tipsTodayBalance = tipsTodayBalanceResult.data;
    }
    callback(result);
  } catch (e) {
    handleError({ message: e });
  }
}

function* getProviderStatus({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().PROVIDERS(merchantTraceId).ME());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(false);
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getProviders({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().PROVIDERS(merchantTraceId).GET());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data.elements);
    } else {
      callback([]);
    }
  } catch (e) {
    handleError({ message: e });
    callback([]);
  }

  yield put(businessActions.setLoading(false));
}

function* getServices({ providerTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const result = yield API.get(API_PATHS().PROVIDERS().AVAILABLE_PRODUCTS(providerTraceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data.elements);
    } else {
      callback([]);
    }
  } catch (e) {
    handleError({ message: e });
    callback([]);
  }

  yield put(businessActions.setLoading(false));
}

function* addSubscription({ data, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const result = yield API.post(API_PATHS().MERCHANTS().MANAGE_SUBSCRIPTIONS.SUBSCRIPTIONS(), data);

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      throw result.message.errors[0];
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* updateSubscription({ subscriptionTraceId, data, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const result = yield API.put(API_PATHS().MERCHANTS().MANAGE_SUBSCRIPTIONS.SUBSCRIPTIONS(subscriptionTraceId), data);

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      throw result.message.errors[0];
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getSubscriptions({
  page,
  branchTraceId,
  providerTraceId,
  productTraceId,
  createdAtFrom,
  createdAtTo,
  isActive,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        providerTraceId,
        productTraceId,
        createdAtFrom,
        createdAtTo,
        isActive,
        page,
        count: 15,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTIONS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getSubscription({ subscriptionTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTIONS(subscriptionTraceId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* cancelSubscription({ subscriptionTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_STATUS(subscriptionTraceId),
      false
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getPendingPayments({
  branchTraceId,
  subscriptionTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  providerTraceId,
  productTraceId,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        subscriptionTraceId,
        statuses: ['PENDING_MANUAL_APPROVAL'],
        page,
        orderByField,
        orderByDesc,
        count: 15,
        chargeRequestDateFrom,
        chargeRequestDateTo,
        providerTraceId,
        productTraceId,
      },
      qsConfig
    );

    const result = yield API.get(
      API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_CHARGES()
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getPaymentsHistory({
  branchTraceId,
  subscriptionTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  providerTraceId,
  productTraceId,
  status,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const statuses = status ? (status.indexOf('|') > 0 ? status.split('|') : [status]) : null;

    const search = qs.stringify(
      {
        branchTraceId,
        subscriptionTraceId,
        statuses: statuses || [
          'APPROVED',
          'PARTIAL_EXECUTED',
          'CANCELED',
          'COMPLETED',
          'ERROR',
          'IN_PROGRESS',
          'MANUAL_REJECTED',
        ],
        page,
        orderByField,
        orderByDesc,
        count: 15,
        chargeRequestDateFrom,
        chargeRequestDateTo,
        providerTraceId,
        productTraceId,
      },
      qsConfig
    );

    const result = yield API.get(
      API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_CHARGES()
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* subscriptionApprovePayment({ subscriptionTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_CHARGE_STATE(subscriptionTraceId),
      'APPROVED'
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* subscriptionRejectPayment({ subscriptionTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_CHARGE_STATE(subscriptionTraceId),
      'MANUAL_REJECTED'
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getSubscriptionPayment({ paymentTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_CHARGES(paymentTraceId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProducts({ branchTraceId, productTraceId, isActive, page, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        traceId: productTraceId,
        isActive,
        page,
        count: 15,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS(merchantTraceId).PRODUCTS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(
        typeof result.data.elements !== 'undefined'
          ? result.data
          : {
              count: 1,
              elements: [result.data],
              hasNext: false,
              page: 1,
            }
      );
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProduct({ productTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().PROVIDERS(merchantTraceId).PRODUCTS(productTraceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* updateProduct({ productTraceId, data, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(API_PATHS().PROVIDERS(merchantTraceId).PRODUCTS(productTraceId), data);

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      throw result.message.errors[0];
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getSubscribers({ page, createdAtFrom, createdAtTo, productTraceId, isActive, merchantName, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        page,
        createdAtFrom,
        createdAtTo,
        productTraceId,
        isActive,
        merchantName,
        count: 15,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS(merchantTraceId).SUBSCRIBERS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getSubscriber({ subscriberTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().PROVIDERS(merchantTraceId).SUBSCRIBERS(subscriberTraceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getPendingCharges({
  subscriptionTraceId,
  productTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  merchantName,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        subscriptionTraceId,
        productTraceId,
        statuses: ['PENDING_MANUAL_APPROVAL'],
        page,
        chargeRequestDateFrom,
        chargeRequestDateTo,
        merchantName,
        orderByField,
        orderByDesc,
        count: 15,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS(merchantTraceId).SUBSCRIBER_CHARGES());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getChargesHistory({
  subscriptionTraceId,
  productTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  merchantName,
  status,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const statuses = status ? (status.indexOf('|') > 0 ? status.split('|') : [status]) : null;

    const search = qs.stringify(
      {
        subscriptionTraceId,
        productTraceId,
        statuses: statuses || [
          'APPROVED',
          'PARTIAL_EXECUTED',
          'CANCELED',
          'COMPLETED',
          'ERROR',
          'IN_PROGRESS',
          'MANUAL_REJECTED',
        ],
        page,
        chargeRequestDateFrom,
        chargeRequestDateTo,
        merchantName,
        orderByField,
        orderByDesc,
        count: 15,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS(merchantTraceId).SUBSCRIBER_CHARGES());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* addCharge({ data, additionalData, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    for (const key in additionalData) {
      if (additionalData.hasOwnProperty(key)) {
        for (const subKey in additionalData[key]) {
          if (additionalData.hasOwnProperty(key)) {
            formData.append(`AdditionalData[${key}][${subKey}]`, additionalData[key][subKey]);
          }
        }
      }
    }
    const result = yield API.post(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_ADD_CHARGE(),
      formData,
      false,
      {
        headers: {},
        formData: true,
      }
    );
    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else if (result.message?.firstError === 'La referencia ya fue utilizada en el servicio de esta subscripcion') {
      throw new Error('La referencia interna ingresada ya fue utilizada.');
    } else {
      throw new Error();
    }
  } catch (e) {
    handleError({ message: e?.message || e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getCharge({ chargeTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().PROVIDERS(merchantTraceId).SUBSCRIBER_CHARGES(chargeTraceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* retryCharge({ messageId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.post(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_RETRY_CHARGE(messageId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* cancelCharge({ chargeTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.delete(
      API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_CHARGE(chargeTraceId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsMerchant({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).GET());
    
    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    console.log(e)
    handleError({ message: e });
    callback(false);
  }
}

function* getSubscriptionProviders({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_PROVIDERS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getSubscriptionServices({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.SUBSCRIPTION_SERVICES());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProviderServices({ callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().PROVIDERS(merchantTraceId).PRODUCTS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsPlans({
  branchTraceId,
  subscriptionPlanStatus,
  subscriptionPlanType,
  currency,
  planName,
  count,
  page,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        subscriptionPlanType,
        currency,
        name: planName,
        subscriptionPlanStatus,
        page,
        count,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).PLANS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* toggleProvidersSubscriptionsPlanStatus({ subscriptionPlanTraceId, subscriptionPlanStatus, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(
      API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).UPDATE_PLAN_STATUS(subscriptionPlanTraceId),
      {
        status: subscriptionPlanStatus,
      }
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsPlanShareLink({ subscriptionPlanTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).GET_SHARE_LINK(subscriptionPlanTraceId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* providersSubscriptionsAddPlan({ data, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.post(API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).ADD_PLAN(), data);

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw result.message;
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* providersSubscriptionsUpdatePlan({ subscriptionPlanTraceId, data, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(
      API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).PLAN(subscriptionPlanTraceId),
      data
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw new Error();
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsPlan({ subscriptionPlanTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).PLAN(subscriptionPlanTraceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      throw new Error();
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsSubscriptions({
  branchTraceId,
  subscriberTraceId,
  subscriptionPlanTraceId,
  subscriptionStatus,
  fromDate,
  untilDate,
  page,
  count,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        subscriberTraceId,
        subscriptionPlanTraceId,
        subscriptionStatus,
        fromDate,
        untilDate,
        page,
        count,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).SUBSCRIPTIONS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsSubscribers({ page, count, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        page,
        count,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).SUBSCRIBERS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* updateProvidersSubscriptionsSubscriptionStatus({ subscriptionTraceId, status, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify({ updateSubscriptionStatus: status }, qsConfig);

    const result = yield API.put(
      API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).UPDATE_SUBSCRIPTION_STATUS(subscriptionTraceId, status)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsSubscription({ subscriptionTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).SUBSCRIPTION(subscriptionTraceId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsTransactions({
  transactionTraceId,
  subscriptionTraceId,
  transactionStatus,
  fromDate,
  untilDate,
  page,
  count = 15,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      { transactionTraceId, subscriptionTraceId, transactionStatus, fromDate, untilDate, page, count },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).TRANSACTIONS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* updateProvidersSubscriptionsSubscriber({ subscriberId, data, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.put(
      API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).UPDATE_SUBSCRIBER(subscriberId),
      data
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(true);
    } else {
      throw result.message.errors[0];
    }
  } catch (e) {
    handleError({ message: e });
    callback(false);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsPromotions({
  subscriptionPlanTraceId,
  promotionStatus,
  promotionType,
  appliesTo,
  name,
  count,
  page,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        subscriptionPlanTraceId,
        promotionStatus,
        promotionType,
        appliesTo,
        name,
        count,
        page,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).PROMOTIONS());

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* toggleProvidersSubscriptionsPromotion({ promotionTraceId, subscriptionPlanTraceId, promotionStatus, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        subscriptionPlanTraceId,
      },
      qsConfig
    );
    
    const result = yield API.put(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).PROMOTION(promotionTraceId), {
      promotionStatus,
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsPromotion({ promotionTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).PROMOTION(promotionTraceId));

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* addProvidersSubscriptionsPromotion({ subscriptionPlanTraceId, branchTraceId, payload, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        subscriptionPlanTraceId,
        branchTraceId,
      },
      qsConfig
    );

    const result = yield API.post(
      API_PATHS(search)
        .PROVIDERS_SUBSCRIPTIONS(merchantTraceId)
        .ADD_PROMOTION(payload.appliesTo === 'NEW', subscriptionPlanTraceId),
      payload
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsSubscriptionShareLink({ subscriptionTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).GET_SUBSCRIPTION_SHARE_LINK(subscriptionTraceId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsTermsAndConditionsLink({ branchTraceId, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);
    const result = yield API.get(
      API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).TERMS_AND_CONDITIONS(branchTraceId)
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* getProvidersSubscriptionsAddTermsAndConditions({ branchTraceId, file, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const formData = new FormData();
    formData.append('file', file);

    const result = yield API.post(
      API_PATHS().PROVIDERS_SUBSCRIPTIONS(merchantTraceId).ADD_TERMS_AND_CONDITIONS(branchTraceId),
      formData,
      null,
      { headers: {}, formData: true }
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* exportSubscriptions({
  branchTraceId,
  page,
  createdAtFrom,
  createdAtTo,
  providerTraceId,
  productTraceId,
  isActive,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        page,
        count: 15,
        createdAtFrom,
        createdAtTo,
        providerTraceId,
        productTraceId,
        isActive,
      },
      qsConfig
    );

    const result = yield API.get(
      API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.EXPORT_SUBSCRIPTIONS(),
      { file: true }
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* exportSubscriptionPayments({
  branchTraceId,
  providerTraceId,
  orderByField,
  orderByDesc,
  page,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  productTraceId,
  status,
  exportPendingCharges,
  isSubscription,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        [isSubscription ? 'subscriptionTraceId' : 'providerTraceId']: providerTraceId,
        orderByField,
        orderByDesc,
        page,
        count: 15,
        chargeRequestDateFrom,
        chargeRequestDateTo,
        productTraceId,
        statuses: status
          ? [status]
          : exportPendingCharges
          ? ['PENDING_MANUAL_APPROVAL']
          : ['APPROVED', 'PARTIAL_EXECUTED', 'CANCELED', 'COMPLETED', 'ERROR', 'IN_PROGRESS', 'MANUAL_REJECTED'],
      },
      qsConfig
    );

    const result = yield API.get(
      API_PATHS(search).MERCHANTS(merchantTraceId).MANAGE_SUBSCRIPTIONS.EXPORT_SUBSCRIPTION_CHARGES(),
      { file: true }
    );

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* exportProviderServices({ page, branchTraceId, productTraceId, isActive, callback }) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        page,
        traceId: productTraceId,
        isActive,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS(merchantTraceId).EXPORT_PROVIDER_SERVICES(), {
      file: true,
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* exportProviderSubscribers({
  page,
  productTraceId,
  createdAtFrom,
  createdAtTo,
  isActive,
  merchantName,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        page,
        productTraceId,
        createdAtFrom,
        createdAtTo,
        isActive,
        merchantName,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS(merchantTraceId).EXPORT_PROVIDER_SUBSCRIBERS(), {
      file: true,
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* exportProviderCharges({
  page,
  productTraceId,
  chargeRequestDateFrom,
  chargeRequestDateTo,
  orderByField,
  orderByDesc,
  merchantName,
  status,
  pendingCharges,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        page,
        productTraceId,
        chargeRequestDateFrom,
        chargeRequestDateTo,
        orderByField,
        orderByDesc,
        merchantName,
        statuses: status
          ? [status]
          : pendingCharges
          ? ['PENDING_MANUAL_APPROVAL']
          : ['APPROVED', 'PARTIAL_EXECUTED', 'CANCELED', 'COMPLETED', 'ERROR', 'IN_PROGRESS', 'MANUAL_REJECTED'],
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS(merchantTraceId).EXPORT_PROVIDER_CHARGES(), {
      file: true,
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* exportProvidersSubscriptionsPlans({
  branchTraceId,
  subscriptionPlanStatus,
  subscriptionPlanType,
  currency,
  planName,
  page,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        subscriptionPlanType,
        currency,
        name: planName,
        subscriptionPlanStatus,
        page,
        count: 15,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).EXPORT_PLANS(), {
      file: true,
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* exportProvidersSubscriptionsSubscriptions({
  branchTraceId,
  subscriberTraceId,
  subscriptionPlanTraceId,
  subscriptionStatus,
  fromDate,
  untilDate,
  page,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        branchTraceId,
        subscriberTraceId,
        subscriptionPlanTraceId,
        subscriptionStatus,
        fromDate,
        untilDate,
        page,
        count: 15,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).EXPORT_SUBSCRIPTIONS(), {
      file: true,
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* exportProvidersSubscriptionsTransactions({
  subscriptionTraceId,
  transactionStatus,
  fromDate,
  untilDate,
  page,
  count,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        subscriptionTraceId,
        transactionStatus,
        fromDate,
        untilDate,
        page,
        count,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).EXPORT_TRANSACTIONS(), {
      file: true,
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

function* exportProvidersSubscriptionsPromotions({
  subscriptionPlanTraceId,
  promotionStatus,
  promotionType,
  name,
  count,
  page,
  callback,
}) {
  yield put(businessActions.setLoading(true));

  try {
    const merchantTraceId = yield select(({ user }) => user.userData.merchantTraceId);

    const search = qs.stringify(
      {
        subscriptionPlanTraceId,
        promotionStatus,
        promotionType,
        name,
        count,
        page,
      },
      qsConfig
    );

    const result = yield API.get(API_PATHS(search).PROVIDERS_SUBSCRIPTIONS(merchantTraceId).EXPORT_PROMOTIONS(), {
      file: true,
    });

    if (result.status === API_RESPONSES.SUCCESS) {
      callback(result.data);
    } else {
      callback(null);
    }
  } catch (e) {
    handleError({ message: e });
    callback(null);
  }

  yield put(businessActions.setLoading(false));
}

export default function* BusinessSagas() {
  yield all([
    takeEvery(businessTypes.INITIALIZE, initialize),
    takeEvery(businessTypes.GET_BRANCHES, getBranches),
    takeEvery(businessTypes.GET_BRANCH_ADDRESS, getBranchAddress),
    takeEvery(businessTypes.GET_BRANCHES_WITH_NO_PAYMENT_METHOD, getBranchesWithNoPaymentMethod),
    takeEvery(businessTypes.GET_ACCOUNTS, getAccounts),
    takeEvery(businessTypes.GET_ACCOUNT_DETAILS, getAccountDetails),
    takeEvery(businessTypes.GET_SUMMARY, getSummary),
    takeEvery(businessTypes.GET_PAYMENT_SCHEDULE, getPaymentSchedule),
    takeEvery(businessTypes.GET_PAYMENT_DETAILS_BY_DAY, getPaymentDetailsByDay),
    takeEvery(businessTypes.GET_OPERATION_DETAILS, getOperationDetails),
    takeEvery(businessTypes.GET_ACTIVITY, getActivity),
    takeEvery(businessTypes.GET_REVERSALS, getReversals),
    takeEvery(businessTypes.SET_PAYMENT_PLAN, setPaymentPlan),
    takeEvery(businessTypes.GET_VOUCHERS, getVouchers),
    takeEvery(businessTypes.GET_HISTORY, getHistory),
    takeEvery(businessTypes.GET_LOANS, getLoans),
    takeEvery(businessTypes.CHECK_LOANS_AVAILABILITY, checkLoansAvailability),
    takeEvery(businessTypes.GET_LOAN_LINK, getLoanLink),
    takeEvery(businessTypes.EXPORT_TABLE, exportTable),
    takeEvery(businessTypes.GET_SECRETS, getSecrets),
    takeEvery(businessTypes.GET_BRANCH_SECRET, getBranchSecret),
    takeEvery(businessTypes.GET_PAYMENT_LINKS, getPaymentLinks),
    takeEvery(businessTypes.CREATE_PAYMENT_LINK, createPaymentLink),
    takeEvery(businessTypes.DELETE_PAYMENT_LINK, deletePaymentLink),
    takeEvery(businessTypes.GET_PAYMENT_LINK, getPaymentLink),
    takeEvery(businessTypes.GET_PAYMENT_LINK_TRANSACTIONS, getPaymentLinkTransactions),
    takeEvery(businessTypes.GET_PAYMENT_LINK_RESTRICTIONS, getPaymentLinkRestrictions),
    takeEvery(businessTypes.EXPORT_PAYMENT_LINKS, exportPaymentLinks),
    takeEvery(businessTypes.GET_NOTIFICATIONS, getNotifications),
    takeEvery(businessTypes.DELETE_NOTIFICATION, deleteNotification),
    takeEvery(businessTypes.CHECK_FOR_ACTIVE_SERVICES, checkForActiveServices),
    takeEvery(businessTypes.GET_MOBILE_BALANCE_STATUS, getMobileBalanceStatus),
    takeEvery(businessTypes.GET_MOBILE_BALANCE, getMobileBalance),
    takeEvery(businessTypes.GET_MOBILE_BALANCE_ACTIVE_POS, getMobileBalanceActivePos),
    takeEvery(businessTypes.GET_MOBILE_BALANCE_HISTORY, getMobileBalanceHistory),
    takeEvery(businessTypes.GET_POS_ALIASES, getPosAliases),
    takeEvery(businessTypes.GET_PHONE_COMPANIES, getPhoneCompanies),
    takeEvery(businessTypes.GET_ITAU_BRANCHES, getItauBranches),
    takeEvery(businessTypes.GET_BRANCH_ISSUERS, getBranchIssuers),
    takeEvery(businessTypes.GET_ITAU_SALES, getItauSales),
    takeEvery(businessTypes.MAKE_ITAU_SALE, makeItauSale),
    takeEvery(businessTypes.GET_TRANSACTIONS_FOR_HOME, getTransactionsForHome),
    takeEvery(businessTypes.GET_HISTORY_FOR_HOME, getHistoryForHome),
    takeEvery(businessTypes.GET_BALANCES_FOR_HOME, getBalancesForHome),
    takeEvery(businessTypes.GET_PROVIDER_STATUS, getProviderStatus),
    takeEvery(businessTypes.GET_PROVIDERS, getProviders),
    takeEvery(businessTypes.GET_SERVICES, getServices),
    takeEvery(businessTypes.ADD_SUBSCRIPTION, addSubscription),
    takeEvery(businessTypes.UPDATE_SUBSCRIPTION, updateSubscription),
    takeEvery(businessTypes.GET_SUBSCRIPTIONS, getSubscriptions),
    takeEvery(businessTypes.GET_SUBSCRIPTION, getSubscription),
    takeEvery(businessTypes.CANCEL_SUBSCRIPTION, cancelSubscription),
    takeEvery(businessTypes.GET_PENDING_PAYMENTS, getPendingPayments),
    takeEvery(businessTypes.GET_PAYMENTS_HISTORY, getPaymentsHistory),
    takeEvery(businessTypes.SUBSCRIPTION_APPROVE_PAYMENT, subscriptionApprovePayment),
    takeEvery(businessTypes.SUBSCRIPTION_REJECT_PAYMENT, subscriptionRejectPayment),
    takeEvery(businessTypes.GET_SUBSCRIPTION_PAYMENT, getSubscriptionPayment),
    takeEvery(businessTypes.GET_PRODUCTS, getProducts),
    takeEvery(businessTypes.GET_PRODUCT, getProduct),
    takeEvery(businessTypes.UPDATE_PRODUCT, updateProduct),
    takeEvery(businessTypes.GET_SUBSCRIBERS, getSubscribers),
    takeEvery(businessTypes.GET_SUBSCRIBER, getSubscriber),
    takeEvery(businessTypes.GET_PENDING_CHARGES, getPendingCharges),
    takeEvery(businessTypes.GET_CHARGES_HISTORY, getChargesHistory),
    takeEvery(businessTypes.ADD_CHARGE, addCharge),
    takeEvery(businessTypes.GET_CHARGE, getCharge),
    takeEvery(businessTypes.RETRY_CHARGE, retryCharge),
    takeEvery(businessTypes.CANCEL_CHARGE, cancelCharge),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_MERCHANT, getProvidersSubscriptionsMerchant),
    takeEvery(businessTypes.GET_SUBSCRIPTION_PROVIDERS, getSubscriptionProviders),
    takeEvery(businessTypes.GET_SUBSCRIPTION_SERVICES, getSubscriptionServices),
    takeEvery(businessTypes.GET_PROVIDER_SERVICES, getProviderServices),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_PLANS, getProvidersSubscriptionsPlans),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_PLAN_SHARE_LINK, getProvidersSubscriptionsPlanShareLink),
    takeEvery(businessTypes.PROVIDERS_SUBSCRIPTIONS_ADD_PLAN, providersSubscriptionsAddPlan),
    takeEvery(businessTypes.PROVIDERS_SUBSCRIPTIONS_UPDATE_PLAN, providersSubscriptionsUpdatePlan),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_PLAN, getProvidersSubscriptionsPlan),
    takeEvery(businessTypes.TOGGLE_PROVIDERS_SUBSCRIPTIONS_PLAN_STATUS, toggleProvidersSubscriptionsPlanStatus),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS, getProvidersSubscriptionsSubscriptions),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIBERS, getProvidersSubscriptionsSubscribers),
    takeEvery(
      businessTypes.UPDATE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION_STATUS,
      updateProvidersSubscriptionsSubscriptionStatus
    ),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION, getProvidersSubscriptionsSubscription),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS, getProvidersSubscriptionsTransactions),
    takeEvery(businessTypes.UPDATE_PROVIDERS_SUBSCRIPTIONS_SUBSCRIBER, updateProvidersSubscriptionsSubscriber),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS, getProvidersSubscriptionsPromotions),
    takeEvery(businessTypes.UPDATE_PROVIDERS_SUBSCRIPTIONS_PROMOTION, toggleProvidersSubscriptionsPromotion),
    takeEvery(businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_PROMOTION, getProvidersSubscriptionsPromotion),
    takeEvery(businessTypes.ADD_PROVIDERS_SUBSCRIPTIONS_PROMOTION, addProvidersSubscriptionsPromotion),
    takeEvery(
      businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTION_SHARE,
      getProvidersSubscriptionsSubscriptionShareLink
    ),
    takeEvery(
      businessTypes.GET_PROVIDERS_SUBSCRIPTIONS_TERMS_AND_CONDITIONS_LINK,
      getProvidersSubscriptionsTermsAndConditionsLink
    ),
    takeEvery(
      businessTypes.PROVIDERS_SUBSCRIPTIONS_ADD_TERMS_AND_CONDITIONS,
      getProvidersSubscriptionsAddTermsAndConditions
    ),
    takeEvery(businessTypes.EXPORT_SUBSCRIPTIONS, exportSubscriptions),
    takeEvery(businessTypes.EXPORT_SUBSCRIPTION_PAYMENTS, exportSubscriptionPayments),
    takeEvery(businessTypes.EXPORT_PROVIDER_SERVICES, exportProviderServices),
    takeEvery(businessTypes.EXPORT_PROVIDER_SUBSCRIBERS, exportProviderSubscribers),
    takeEvery(businessTypes.EXPORT_PROVIDER_CHARGES, exportProviderCharges),
    takeEvery(businessTypes.EXPORT_PROVIDERS_SUBSCRIPTIONS_PLANS, exportProvidersSubscriptionsPlans),
    takeEvery(businessTypes.EXPORT_PROVIDERS_SUBSCRIPTIONS_SUBSCRIPTIONS, exportProvidersSubscriptionsSubscriptions),
    takeEvery(businessTypes.EXPORT_PROVIDERS_SUBSCRIPTIONS_TRANSACTIONS, exportProvidersSubscriptionsTransactions),
    takeEvery(businessTypes.EXPORT_PROVIDERS_SUBSCRIPTIONS_PROMOTIONS, exportProvidersSubscriptionsPromotions),
  ]);
}
