import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Language } from '../../../utils';
import { user as userActions } from '../../../actions';
import { Button } from '../../../components';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import makeClasses from '../styles';
import clsx from 'clsx';
import FileUploader from '../../../components/FileUploader';

const StepFour = ({ history, nextStep, prevStep, handleChange, stepOneData }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const { loading, languageTexts } = useSelector((state) => ({
    loading: state.user.loading,
    languageTexts: state.language.texts || {},
  }));

  const i18n = Language(languageTexts);

  const [mccs, setMcc] = useState([]);
  const [countries, setCountries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState([]);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [businessNameTypes, setBusinessNameTypes] = useState([]);

  const [inputAddress, setInputAddress] = useState('');
  const [inputAddressNumber, setInputAddressNumber] = useState('');
  const [inputAddressApartment, setInputAddressApartment] = useState('');
  const [inputAddressCorner, setInputAddressCorner] = useState('');
  const [inputName, setInputName] = useState('');

  const [inputMcc, setInputMcc] = useState(null);
  const [inputPaymentPlans, setInputPaymentPlans] = useState(null);
  const [inputCountry, setInputCountry] = useState(null);
  const [inputDepartment, setInputDepartment] = useState(null);
  const [inputCity, setInputCity] = useState(null);
  const [inputBusinessNameTypes, setInputBusinessNameTypes] = useState(null);

  const [fileNameExemption, setFileNameExemption] = useState([]);

  const [filesExemption, setFilesExemption] = useState([]);

  const [filesDGI, setFilesDGI] = useState([]);

  const canMoveToStep5 =
    inputCity !== null &&
    inputAddress.length > 0 &&
    inputMcc !== null &&
    inputCountry !== null &&
    inputDepartment !== null &&
    inputAddressNumber.length > 0 &&
    inputAddressCorner.length > 0 &&
    inputName.length > 0 &&
    inputBusinessNameTypes !== null &&
    inputPaymentPlans !== null &&
    filesDGI.length > 0;

  const handleNext = () => {
    if (inputAddress.trim().length === 0) {
      return toast.error(i18n.get('LegalEntityRegistration.RegisterInvalidAddress'), { autoClose: 5000 });
    }

    if (inputName.trim().length === 0) {
      return toast.error(i18n.get('LegalEntityRegistration.RegisterInvalidName'), { autoClose: 5000 });
    }

    const address = {
      cityId: inputCity,
      street: inputAddress.trim(),
      corner: inputAddressCorner.trim(),
      postalCode: null,
      number: inputAddressNumber.trim(),
      apartment: inputAddressApartment.trim(),
      description: null,
      longitude: 0,
      latitude: 0,
    };

    const name = inputName.trim();
    const businessNameTypeId = inputBusinessNameTypes;
    const mccId = inputMcc;
    const paymentPlanId = inputPaymentPlans;

    dispatch(
      userActions.merchantAffiliationData(
        stepOneData,
        {
          address: address,
          name: name,
          businessNameTypeId: businessNameTypeId,
          mccId: mccId,
          paymentPlanId: paymentPlanId,
        },
        filesDGI,
        filesExemption,
        (result) =>
          result == 'ok'
            ? nextStep({ data: stepOneData })
            : toast.error(i18n.get('LegalEntityRegistration.UploadError'))
      )
    );
  };

  useEffect(() => {
    dispatch(
      userActions.getCountries((countries) => {
        setCountries(countries);
        countries.forEach((country) => {
          if (country.alphaCode2 === 'UY') {
            setInputCountry(country.id);
          }
        });
      })
    );
  }, []);

  useEffect(() => {
    if (inputCountry) {
      dispatch(
        userActions.getDepartmentsByCountry(inputCountry, (departments) => {
          setDepartments(departments);
        })
      );
    }
  }, [inputCountry]);

  useEffect(() => {
    if (inputDepartment) {
      dispatch(
        userActions.getCitiesByDepartments(inputDepartment, (cities) => {
          setCities(cities);
        })
      );
    }
  }, [inputDepartment]);

  useEffect(() => {
    dispatch(
      userActions.getMccs((mccs) => {
        setMcc(mccs);
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      userActions.getPaymentPlans((paymentPlans) => {
        setPaymentPlans(paymentPlans);
      })
    );
  }, [inputPaymentPlans]);

  useEffect(() => {
    dispatch(
      userActions.getBusinessNameTypes((businessNameTypes) => {
        setBusinessNameTypes(businessNameTypes);
      })
    );
  }, [inputBusinessNameTypes]);

  const _handleOnChangeMcc = (e, newValue) => {
    setInputMcc(newValue);
  };

  const _handleOnChangePaymentPlans = (e, newValue) => {
    setInputPaymentPlans(newValue);
  };

  const _handleOnChangeBusinessNameTypes = (e, newValue) => {
    setInputBusinessNameTypes(newValue);
  };

  const _handleOnChangeCountry = (e, newValue) => {
    setInputDepartment(null);
    setInputCity(null);
    setDepartments([]);
    setCities([]);
    setInputCountry(newValue);
  };

  const _handleOnChangeDepartment = (e, newValue) => {
    setInputCity(null);
    setCities([]);
    setInputDepartment(newValue);
  };

  const _handleOnChangeCity = (e, newValue) => {
    setInputCity(newValue);
  };

  const _getOptionLabel = (array) => (option) => {
    let foundOption = null;

    if (array === 'mccs') {
      foundOption = mccs.find((m) => m.id === option);
    } else if (array === 'paymentPlans') {
      foundOption = paymentPlans.find((c) => c.id === option);
    } else if (array === 'businessNameTypes') {
      foundOption = businessNameTypes.find((b) => b.id === option);
    } else if (array === 'countries') {
      foundOption = countries.find((c) => c.id === option);
    } else if (array === 'cities') {
      foundOption = cities.find((c) => c.id === option);
    } else if (array === 'departments') {
      foundOption = departments.find((c) => c.id === option);
    }

    if (foundOption) {
      return foundOption.name;
    }

    return '';
  };

  const handleFileChange = (newFiles, setFile) => {
    setFile(newFiles);
  };

  return (
    <React.Fragment>
      <div className={classes.formContent}>
        <div className={classes.formTitleContainer}>
          <div className={classes.formTitle}>{i18n.get('LegalEntityRegistration.StepFormTitle')}</div>
          <div className={classes.formText}>{i18n.get('LegalEntityRegistration.Step4FormText')}</div>
        </div>
        <form layout="vertical" onSubmit={handleNext} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Autocomplete
                options={mccs.map((m) => m.id)}
                getOptionLabel={_getOptionLabel('mccs')}
                onChange={_handleOnChangeMcc}
                classes={{ inputRoot: classes.formInputSelect }}
                value={inputMcc}
                renderInput={(params) => (
                  <TextField
                    className={classes.formInput}
                    classes={{ root: classes.formInput }}
                    variant="outlined"
                    placeholder={i18n.get('LegalEntityRegistration.InputMcc')}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} xl={4}>
              <Autocomplete
                options={countries.map((c) => c.id)}
                getOptionLabel={_getOptionLabel('countries')}
                onChange={_handleOnChangeCountry}
                classes={{ inputRoot: classes.formInputSelect }}
                disabled
                value={inputCountry}
                renderInput={(params) => (
                  <TextField
                    className={classes.formInput}
                    classes={{ root: classes.formInput }}
                    variant="outlined"
                    placeholder={i18n.get('LegalEntityRegistration.InputCountryTitle')}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} xl={4}>
              <Autocomplete
                options={departments.map((d) => d.id)}
                getOptionLabel={_getOptionLabel('departments')}
                onChange={_handleOnChangeDepartment}
                classes={{ inputRoot: classes.formInputSelect }}
                disabled={!inputCountry}
                value={inputDepartment}
                renderInput={(params) => (
                  <TextField
                    className={classes.formInput}
                    classes={{ root: classes.formInput }}
                    variant="outlined"
                    placeholder={i18n.get('LegalEntityRegistration.InputDepartmentTitle')}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4}>
              <Autocomplete
                options={cities.map((c) => c.id)}
                getOptionLabel={_getOptionLabel('cities')}
                onChange={_handleOnChangeCity}
                classes={{ inputRoot: classes.formInputSelect }}
                disabled={!inputDepartment}
                value={inputCity}
                renderInput={(params) => (
                  <TextField
                    className={classes.formInput}
                    classes={{ root: classes.formInput }}
                    variant="outlined"
                    placeholder={i18n.get('LegalEntityRegistration.InputCityTitle')}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <TextField
                placeholder={i18n.get('LegalEntityRegistration.InputStreetTitle')}
                className={classes.formInput}
                onChange={(e) => setInputAddress(e.target.value)}
                classes={{ root: classes.formInput }}
                variant="outlined"
                value={inputAddress}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} xl={4}>
              <TextField
                placeholder={i18n.get('LegalEntityRegistration.InputNumberTitle')}
                className={classes.formInput}
                onChange={(e) => setInputAddressNumber(e.target.value)}
                classes={{ root: classes.formInput }}
                variant="outlined"
                value={inputAddressNumber}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} xl={4}>
              <TextField
                placeholder={i18n.get('LegalEntityRegistration.InputApartmentTitle')}
                className={classes.formInput}
                onChange={(e) => setInputAddressApartment(e.target.value)}
                classes={{ root: classes.formInput }}
                variant="outlined"
                value={inputAddressApartment}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} xl={4}>
              <TextField
                placeholder={i18n.get('LegalEntityRegistration.InputCornerTitle')}
                className={classes.formInput}
                onChange={(e) => setInputAddressCorner(e.target.value)}
                classes={{ root: classes.formInput }}
                variant="outlined"
                value={inputAddressCorner}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <TextField
                placeholder={i18n.get('LegalEntityRegistration.InputName')}
                className={classes.formInput}
                onChange={(e) => setInputName(e.target.value)}
                classes={{ root: classes.formInput }}
                variant="outlined"
                value={inputName}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} xl={6}>
              <Autocomplete
                options={businessNameTypes.map((b) => b.id)}
                getOptionLabel={_getOptionLabel('businessNameTypes')}
                onChange={_handleOnChangeBusinessNameTypes}
                classes={{ inputRoot: classes.formInputSelect }}
                value={inputBusinessNameTypes}
                renderInput={(params) => (
                  <TextField
                    className={classes.formInput}
                    classes={{ root: classes.formInput }}
                    variant="outlined"
                    placeholder={i18n.get('LegalEntityRegistration.InputLegalNature')}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} xl={6}>
              <Autocomplete
                options={paymentPlans.map((p) => p.id)}
                getOptionLabel={_getOptionLabel('paymentPlans')}
                onChange={_handleOnChangePaymentPlans}
                classes={{ inputRoot: classes.formInputSelect }}
                value={inputPaymentPlans}
                renderInput={(params) => (
                  <TextField
                    className={classes.formInput}
                    classes={{ root: classes.formInput }}
                    variant="outlined"
                    placeholder={i18n.get('LegalEntityRegistration.InputPaymentPlans')}
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              <div className={classes.formInputTitle}>{i18n.get('LegalEntityRegistration.DGIForm')}</div>
              <FileUploader
                onFileChange={(files) => handleFileChange(files, setFilesDGI)}
                title={i18n.get('LegalEntityRegistration.InputDGITitle')}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} xl={6}>
              <div className={classes.formInputTitle}>{i18n.get('LegalEntityRegistration.WithholdingExemption')}</div>
              <FileUploader
                onFileChange={(files) => handleFileChange(files, setFilesExemption)}
                title={i18n.get('LegalEntityRegistration.InputDDJJTitle')}
                templatePath={'./template_ddjj_exoneraciones.pdf'}
                templateName="Plantilla-Exoneración-de-retenciones"
              />
            </Grid>
          </Grid>
        </form>
      </div>

      <div className={clsx(classes.formSubmitContainer, classes.formSubmitContainerWithOneMiniButton)}>
        <Button className={classes.formSubmitButton} disabled={loading || !canMoveToStep5} onClick={handleNext}>
          {i18n.get('LegalEntityRegistration.NextButton')}
        </Button>
      </div>
    </React.Fragment>
  );
};
StepFour.id = 'com.Handy.LegalEntityRegistration.StepFour';
export default StepFour;
