export const INITIALIZE = 'com.Handy.user.INITIALIZE';
export const USER_LOGGED_IN = 'com.Handy.user.USER_LOGGED_IN';
export const SET_LOADING = 'com.Handy.user.SET_LOADING';
export const SET_USER_DATA = 'com.Handy.user.SET_USER_DATA';
export const LOGIN = 'com.Handy.user.LOGIN';
export const LOGIN_ADDITIONAL = 'com.Handy.user.LOGIN_ADDITIONAL';
export const CLEAR = 'com.Handy.user.CLEAR';
export const LOGOUT = 'com.Handy.user.LOGOUT';
export const USER_LOGGED_OUT = 'com.Handy.user.USER_LOGGED_OUT';
export const VERIFY_ACCOUNT = 'com.Handy.user.VERIFY_ACCOUNT';
export const VERIFY_ACCOUNT_ADDITIONAL = 'com.Handy.user.VERIFY_ACCOUNT_ADDITIONAL';
export const GET_PROFILE = 'com.Handy.user.GET_PROFILE';
export const SET_PROFILE_DATA = 'com.Handy.user.SET_PROFILE_DATA';
export const GET_PROFILE_DATA = 'com.Handy.user.GET_PROFILE_DATA';
export const GET_USER_ROLES = 'com.Handy.user.GET_USER_ROLES';
export const GET_USER = 'com.Handy.user.GET_USER';
export const GET_USERS = 'com.Handy.user.GET_USERS';
export const CREATE_USER = 'com.Handy.user.CREATE_USER';
export const UPDATE_USER = 'com.Handy.user.UPDATE_USER';
export const UPDATE_PASSWORD = 'com.Handy.user.UPDATE_PASSWORD';
export const ENABLE_USER = 'com.Handy.user.ENABLE_USER';
export const DISABLE_USER = 'com.Handy.user.DISABLE_USER';
export const DELETE_USER = 'com.Handy.user.DELETE_USER';
export const GET_USERS_COUNT = 'com.Handy.user.GET_USERS_COUNT';
export const REQUEST_CHANGE_EMAIL = 'com.Handy.user.REQUEST_CHANGE_EMAIL';
export const REQUEST_CHANGE_PHONE_NUMBER = 'com.Handy.user.REQUEST_CHANGE_PHONE_NUMBER';
export const VERIFY_DATA_CHANGE = 'com.Handy.user.VERIFY_DATA_CHANGE';
export const VERIFY_CHANGE_PHONE_NUMBER = 'com.Handy.user.VERIFY_CHANGE_PHONE_NUMBER';
export const GET_CATEGORIES = 'com.Handy.user.GET_CATEGORIES';
export const GET_COUNTRIES = 'com.Handy.user.GET_COUNTRIES';
export const GET_DEPARTMENTS_BY_COUNTRY = 'com.Handy.user.GET_DEPARTMENTS_BY_COUNTRY';
export const GET_CITIES_BY_DEPARTMENTS = 'com.Handy.user.GET_CITIES_BY_DEPARTMENTS';
export const START_AFFILIATION = 'com.Handy.user.START_AFFILIATION';
export const AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE = 'com.Handy.user.AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE';
export const AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE = 'com.Handy.user.AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE';
export const AFFILIATION_VERIFY_VERIFICATION_CODE = 'com.Handy.user.AFFILIATION_VERIFY_VERIFICATION_CODE';
export const AFFILIATION_GET_IDENTITY_VERIFICATION_LINK = 'com.Handy.user.AFFILIATION_GET_IDENTITY_VERIFICATION_LINK';
export const AFFILIATION_GET_IDENTITY_VERIFICATION_STATUS =
  'com.Handy.user.AFFILIATION_GET_IDENTITY_VERIFICATION_STATUS';
export const GET_PAYMENT_METHODS = 'com.Handy.business.GET_PAYMENT_METHODS';
export const GET_PENDING_PAYMENT_METHODS = 'com.Handy.business.GET_PENDING_PAYMENT_METHODS';
export const GET_PAYMENT_METHODS_BY_BRANCHES = 'com.Handy.business.GET_PAYMENT_METHODS_BY_BRANCHES';
export const GET_PAYMENT_METHOD_FULL_ACCOUNT_NUMBER = 'com.Handy.business.GET_PAYMENT_METHOD_FULL_ACCOUNT_NUMBER';

export const GET_PAYMENT_ENTITIES = 'com.Handy.business.GET_PAYMENT_ENTITIES';
export const GET_PAYMENT_CURRENCIES = 'com.Handy.business.GET_PAYMENT_CURRENCIES';
export const GET_PAYMENT_ENTITY_REGEX = 'com.Handy.business.GET_PAYMENT_ENTITY_REGEX';
export const ADD_PAYMENT_METHOD = 'com.Handy.business.ADD_PAYMENT_METHOD';
export const VERIFY_CHANGE_PAYMENT_METHOD = 'com.Handy.business.VERIFY_CHANGE_PAYMENT_METHOD';
export const ASSIGN_BRANCHES_TO_PAYMENT_METHOD = 'com.Handy.business.ASSIGN_BRANCHES_TO_PAYMENT_METHOD';
export const BUSINESS_NAME_TYPES = 'com.Handy.user.BUSINESS_NAME_TYPES';
export const MERCHANT_START_AFFILIATION = 'com.Handy.user.MERCHANT_START_AFFILIATION';
export const MERCHANT_AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE =
  'com.Handy.user.MERCHANT_AFFILIATION_REQUEST_EMAIL_VERIFICATION_CODE';
export const MERCHANT_AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE =
  'com.Handy.user.MERCHANT_AFFILIATION_REQUEST_PHONE_VERIFICATION_CODE';
export const MERCHANT_AFFILIATION_VERIFY_VERIFICATION_CODE =
  'com.Handy.user.MERCHANT_AFFILIATION_VERIFY_VERIFICATION_CODE';
export const GET_MCCS = 'com.Handy.user.GET_MCCS';
export const GET_PAYMENT_PLANS = 'com.Handy.user.GET_PAYMENT_PLANS';
export const MERCHANT_AFFILIATION_DATA = 'com.Handy.user.MERCHANT_AFFILIATION_DATA';
export const GET_POSITIONS = 'com.Handy.user.GET_POSITIONS';
export const MERCHANT_REPRESENTATIVE_DATA = 'com.Handy.user.MERCHANT_REPRESENTATIVE_DATA';
export const MERCHANT_IDENTITY_VERIFICATION = 'com.Handy.user.MERCHANT_IDENTITY_VERIFICATION';
export const MERCHANT_AFFILIATION_VALIDATE_EMAIL = 'com.Handy.user.MERCHANT_AFFILIATION_VALIDATE_EMAIL';
export const MERCHANT_AFFILIATION_VALIDATE_PHONE = 'com.Handy.user.MERCHANT_AFFILIATION_VALIDATE_PHONE';
export const GET_POS_REQUESTS = 'com.Handy.user.GET_POS_REQUESTS';
export const GET_PENDING_POS_REQUESTS = 'com.Handy.user.GET_PENDING_POS_REQUESTS';
export const GET_POS_REQUESTS_HISTORY = 'com.Handy.user.GET_POS_REQUESTS_HISTORY';
export const GET_POS_TYPES = 'com.Handy.user.GET_POS_TYPES';
export const ADD_POS_REQUEST = 'com.Handy.user.ADD_POS_REQUEST';
export const CANCEL_POS_REQUEST = 'com.Handy.user.CANCEL_POS_REQUEST';
export const GET_POS_BY_BRANCHES = 'com.Handy.user.GET_POS_BY_BRANCHES';
export const GET_TOKEN = 'com.Handy.user.GET_TOKEN';
