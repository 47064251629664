import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BoxSeparator,
  Box,
  ForbiddenSection,
  Page,
  TitleBox,
  Button,
  Confirm,
  ContentBox,
  StatusPill,
  OperationsTable,
} from '../../../../../components';
import ROUTES from '../../../../../routes';
import { Language, Utils } from '../../../../../utils';
import { business as businessActions } from '../../../../../actions';
import { goBack } from 'connected-react-router';
import makeClasses from './styles';
import moment from 'moment';
import { Grid } from '@material-ui/core';

import { Policies } from '../../../../../utils/Policies';
import { toast } from 'react-toastify';
import { Alert, AlertTitle } from '@material-ui/lab';
import clsx from 'clsx';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const STATUS_OPTIONS = [
  { id: 'CANCELED', label: 'Cancelado' },
  { id: 'COMPLETED', label: 'Cobrado' },
  { id: 'ERROR', label: 'Error' },
  { id: 'PARTIAL_EXECUTED', label: 'Parcialmente cobrado' },
  { id: 'IN_PROGRESS', label: 'Procesando cobro' },
  { id: 'APPROVED', label: 'Procesando cobro' },
  { id: 'MANUAL_REJECTED', label: 'Rechazado por usuario' },
  { id: 'PENDING_MANUAL_APPROVAL', label: 'Pendiente de aprobación' },
];

const SubscriberChargeDetailsScreen = ({ match }) => {
  const dispatch = useDispatch();
  const classes = makeClasses();

  const { policies, languageTexts } = useSelector((state) => ({
    policies: state.user.userData.policies || [],
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);
  const subscriptionTraceId = match && match.params ? match.params.traceId : null;
  const chargeTraceId = match && match.params ? match.params.chargeTraceId : null;

  const [charge, setCharge] = useState(null);
  const [showCancelChargeDialog, setShowCancelChargeDialog] = useState(false);
  const [showRetryChargeDialog, setShowRetryChargeDialog] = useState(false);

  const [forbiddenSection, setForbbidenSection] = useState(false);
  const canManage = policies.includes(Policies.types.PROVIDER_WRITE);

  useEffect(() => {
    if (!policies || !policies.includes(Policies.types.PROVIDER)) {
      setForbbidenSection(true);
    }
  }, [policies]);

  useEffect(() => {
    if (!forbiddenSection) {
      loadData();
    }
  }, [forbiddenSection]);

  const loadData = () => {
    if (subscriptionTraceId) {
      dispatch(
        businessActions.getCharge(chargeTraceId, (data) => {
          if (data.additionalData) {
            for (const key in data.additionalData) {
              if (data.additionalData.hasOwnProperty(key)) {
                data.additionalData[key] = JSON.parse(JSON.parse(data.additionalData[key]));
              }
            }
          }
          setCharge(data);
        })
      );
    }
  };

  const handleOnGoBackClick = () => {
    dispatch(goBack());
  };

  const handleOnRetryCharge = () => {
    toggleRetryChargeDialog();
  };

  const toggleDialog = () => {
    if (showRetryChargeDialog) {
      toggleRetryChargeDialog();
    } else if (showCancelChargeDialog) {
      toggleCancelChargeDialog();
    }
  };

  const toggleRetryChargeDialog = () => {
    setShowRetryChargeDialog(!showRetryChargeDialog);
  };

  const toggleCancelChargeDialog = () => {
    setShowCancelChargeDialog(!showCancelChargeDialog);
  };

  const handleOnConfirm = () => {
    if (showRetryChargeDialog) {
      confirmRetryCharge();
    } else if (showCancelChargeDialog) {
      confirmCancelCharge();
    }
  };

  const confirmRetryCharge = () => {
    toggleDialog();
    dispatch(
      businessActions.retryCharge(charge.messageId, (result) => {
        if (result) {
          toast.success(i18n.get('ProvidersManageSubscribersView.SuccessRetryMessage'));
          loadData();
        } else {
          toast.error(i18n.get('ProvidersManageSubscribersView.ErrorRetryMessage'));
        }
      })
    );
  };

  const confirmCancelCharge = () => {
    toggleDialog();
    dispatch(
      businessActions.cancelCharge(charge.traceId, (result) => {
        if (result) {
          toast.success(i18n.get('ProvidersManageSubscribersView.SuccessCancelMessage'));
          loadData();
        } else {
          toast.error(i18n.get('ProvidersManageSubscribersView.ErrorCancelMessage'));
        }
      })
    );
  };

  const renderChargesHistory = () => {
    const headers = [
      {
        canHide: false,
        columnNumber: 1,
        name: i18n.get('ProvidersManageSubscribersChargeDetails.ChargesHistoryGrid.Column1'),
        sortable: false,
        type: 'date-time',
      },
      {
        canHide: false,
        columnNumber: 2,
        name: i18n.get('ProvidersManageSubscribersChargeDetails.ChargesHistoryGrid.Column2'),
        sortable: false,
      }
    ];
    const body = (charge?.chargePaymentLogs || []).map((item) => ({
      rowId: item.traceId,
      columns: [
        {
          columnNumber: 1,
          data: item.createdAt,
        },
        {
          columnNumber: 2,
          data: `${charge.currency} ${Utils.formatCurrency(item.amount)}`,
        }
      ],
    }));

    return (
      <OperationsTable
        headers={headers}
        body={body}
        emptyMessage={i18n.get('ProvidersManageSubscribersChargeDetails.GridEmptyMessage')}
        showDetails={false}
      />
    );
  };

  if (forbiddenSection) {
    return (
      <Page
        withHeader
        withSidebar
        withHeaderTitle={i18n.get('ProvidersManageSubscribersChargeDetails.Title')}
        withActivePage={ROUTES.MANAGE_PROVIDERS.id}
      >
        <ForbiddenSection />
      </Page>
    );
  }

  return (
    <Page
      withHeader
      withSidebar
      withHeaderTitle={i18n.get('ProvidersManageSubscribersChargeDetails.Title')}
      withActivePage={ROUTES.MANAGE_PROVIDERS.id}
    >
      <TitleBox
        title={i18n.get('ProvidersManageSubscribersChargeDetails.BoxTitle')}
        back
        backTitle={i18n.get('ProvidersManageSubscribersChargeDetails.BackButton')}
        onBackClick={handleOnGoBackClick}
        backButtonVariant="outlined"
      />
      <BoxSeparator size="small" />
      <ContentBox title={i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxDataLabel')}>
        {charge ? (
          <React.Fragment>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxDateLabel')}:</div>
                  {charge.createdAt ? moment(charge.createdAt).format(i18n.get('DateFormat')) : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxLimitDateLabel')}:</div>
                  {charge.limitDate ? moment(charge.limitDate).format(i18n.get('DateFormat')) : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxServiceLabel')}:</div>
                  {charge.productName || '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxUserLabel')}:</div>
                  {charge.merchantName || '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxBranchLabel')}:</div>
                  {charge.branchName || '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxStatusLabel')}:</div>
                  <StatusPill data={charge.status === 'APPROVED' ? 'IN_PROGRESS' : charge.status}>
                    {charge.status ? STATUS_OPTIONS.find((s) => s.id === charge.status)?.label : '-'}
                  </StatusPill>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxAmountLabel')}:</div>
                  {charge.amount ? `${charge.product.currency} ${Utils.formatCurrency(charge.amount)}` : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div> {i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxDueAmountLabel')}:</div>
                  {charge.amount - charge.chargedAmount >= 0
                    ? `${charge.product.currency} ${Utils.formatCurrency(charge.amount - charge.chargedAmount)}`
                    : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxMessageLabel')}:</div>
                  {charge.message || '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxRefLabel')}:</div>
                  {charge.reference || '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.suscriptionDetailsItem}>
                  <div>{i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxBillIdentifierLabel')}:</div>
                  {charge.billIdentifier || '-'}
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : null}
      </ContentBox>
      {charge && charge.additionalData && Object.keys(charge.additionalData).length > 0 ? (
        <React.Fragment>
          <BoxSeparator size="small" />
          <ContentBox title={i18n.get('ProvidersManageSubscribersChargeDetails.SubscriptionBoxAdditionalDataLabel')}>
            {charge.additionalData && Object.keys(charge.additionalData).length > 0
              ? Object.keys(charge.additionalData).map((key, index) => (
                  <div key={index} className={classes.additionalDataContainer}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>
                            {i18n.get('ProvidersManageSubscribersChargeDetails.AdditionalFieldNameInputLabel')}:
                          </div>
                          {charge.additionalData[key].Name || '-'}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.suscriptionDetailsItem}>
                          <div>
                            {i18n.get('ProvidersManageSubscribersChargeDetails.AdditionalFieldValueInputLabel')}:
                          </div>
                          {charge.additionalData[key].Value
                            ? charge.additionalData[key].Type === 'date'
                              ? moment(charge.additionalData[key].Value).format(i18n.get('DateFormat'))
                              : charge.additionalData[key].Value
                            : '-'}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))
              : null}
          </ContentBox>
        </React.Fragment>
      ) : null}

      <BoxSeparator size="small" />
      <Box direction="column">
        <div className={classes.gridTitleWrapper}>
          <div>
            <div>{i18n.get('ProvidersManageSubscribersChargeDetails.ChargesHistoryGridTitle')}</div>
          </div>
        </div>
        <div className={classes.gridWrapper}>
          <div className={classes.gridSeparator} />
          {renderChargesHistory()}
        </div>
      </Box>

      {charge && charge.status !== 'PENDING_MANUAL_APPROVAL' && charge.status !== 'CANCELED' && canManage && charge.status === 'ERROR' ? (
        <React.Fragment>
          <BoxSeparator size="small" />
          <Box>
            <div className={classes.actionsContainer}>
              {charge.status === 'ERROR' ? (
                <Button onClick={handleOnRetryCharge}>
                  {i18n.get('ProvidersManageSubscribersChargeDetails.ButtonRetry')}
                </Button>
              ) : null}
            </div>
          </Box>
        </React.Fragment>
      ) : null}
      
      <Confirm
        open={showRetryChargeDialog || showCancelChargeDialog}
        onClose={toggleDialog}
        title={i18n.get(
          showRetryChargeDialog
            ? 'ProvidersManageSubscribersChargeDetails.RetryChargeDialogTitle'
            : 'ProvidersManageSubscribersChargeDetails.CancelChargeDialogTitle'
        )}
        confirmText={i18n.get('ProvidersManageSubscribersChargeDetails.DialogActionConfirm')}
        onConfirm={handleOnConfirm}
        cancelText={i18n.get('ProvidersManageSubscribersChargeDetails.DialogActionCancel')}
        onCancel={toggleDialog}
      >
        <Alert severity="error" icon={false} className={clsx(classes.alert, classes.alertDanger)}>
          <div>
            <ErrorOutlineOutlinedIcon className={classes.alertIcon} />
          </div>
          <div>
            <AlertTitle className={classes.alertTitle}>
              {i18n.get(
                showRetryChargeDialog
                  ? 'ProvidersManageSubscribersChargeDetails.RetryChargeDialogContentTitle'
                  : 'ProvidersManageSubscribersChargeDetails.CancelChargeDialogContentTitle'
              )}
            </AlertTitle>
            {i18n.get(
              showRetryChargeDialog
                ? 'ProvidersManageSubscribersChargeDetails.RetryChargeDialogContentText'
                : 'ProvidersManageSubscribersChargeDetails.CancelChargeDialogContentText'
            )}
          </div>
        </Alert>
      </Confirm>
    </Page>
  );
};

SubscriberChargeDetailsScreen.id = 'com.Handy.SubscriberChargeDetails';

export default SubscriberChargeDetailsScreen;
