import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { common as commonActions } from "../../actions";
import { Language } from "../../utils";
import ROUTES from "../../routes";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTheme } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  useMediaQuery,
} from "@material-ui/core";
import makeClasses from "./styles";
import homeIcon from "../../static/images/icons/home.png";
import yourMoneyIcon from "../../static/images/icons/yourMoney.svg";
import activityIcon from "../../static/images/icons/activity.svg";
import vouchersIcon from "../../static/images/icons/vouchers.svg";
import reversalsIcon from "../../static/images/icons/reversals.svg";
import historyIcon from "../../static/images/icons/history.svg";
import paymentLinkIcon from "../../static/images/icons/paymentLink.svg";
import profileIcon from "../../static/images/icons/profile.svg";
import manageUsersIcon from "../../static/images/icons/manage-users.svg";
import manageAccountsIcon from "../../static/images/icons/manage-accounts.svg";
import managePosIcon from "../../static/images/icons/manage-pos.svg";
import loansIcon from "../../static/images/icons/loans.png";
import mobileBalanceIcon from "../../static/images/icons/mobile_balance.svg";
import accountIcon from "../../static/images/icons/account.svg";
import ItauIcon from "../../static/images/icons/itau.svg";
import EcommerceIcon from "../../static/images/icons/ecommerce.svg";
import SubscriptonsIcon from "../../static/images/icons/subscriptions.svg";
import Providerscon from "../../static/images/icons/providers.svg";
import appLogo from "../../static/images/logo.svg";
import qrIcon from "../../static/images/icons/qr.svg";
import clsx from "clsx";
import { business as businessActions, user as userActions } from "../../actions";
import { Policies } from "../../utils/Policies";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const SideMenu = ({
  activePage = "",
  withBanner = false,
  withBoxBanner = false,
  withBoxBannerMd = false,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { policies, sideMenuOpen, languageTexts } = useSelector(
    ({ user, common, language }) => ({
      policies: user.userData.policies || [],
      sideMenuOpen: common.sideMenuOpen,
      languageTexts: language.texts || {},
    })
  );

  const [routesSections, setRoutesSections] = useState([]);
  const i18n = Language(languageTexts);

  const classes = makeClasses();

  useEffect(() => {
    if (policies.length > 0) {
      const menu = setUpMenuItems();
      checkForActiveServices(menu);
    }
  }, [policies]);

  const setUpMenuItems = () => {
    const menu = [];

    if (policies.includes(Policies.types.HOME)) {
      menu.push({
        id: ROUTES.HOME.id,
        label: "Menu.Home",
        path: ROUTES.HOME.path,
        icon: (
          <img src={homeIcon} className={classes.listItemIcon} alt="Home" />
        ),
        enabled: true,
      });
    }
    if (
      process.env.REACT_APP_ENABLE_SECTION_ACCOUNT === "YES" &&
      policies.includes(Policies.types.ACCOUNT)
    ) {
      menu.push({
        id: ROUTES.ACCOUNT.id,
        label: "Menu.Account",
        path: ROUTES.ACCOUNT.path,
        icon: (
          <img
            src={accountIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="Account"
          />
        ),
        enabled: true,
      });
    }
    if (
      process.env.REACT_APP_ENABLE_SECTION_ACTIVITY === "YES" &&
      policies.includes(Policies.types.ACTIVITY)
    ) {
      menu.push({
        id: ROUTES.ACTIVITY.id,
        label: "Menu.Activity",
        path: ROUTES.ACTIVITY.path,
        icon: (
          <img
            src={activityIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="Activity"
          />
        ),
        enabled: true,
      });
    }

    const salesItem = {
      type: "section",
      title: "Menu.Sections.Sales",
      items: [],
      icon: (
        <img
          src={yourMoneyIcon}
          className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
          style={{ width: 27 }}
          alt="Sales"
        />
      ),
      iconWrapperStyle: { justifyContent: "flex-start", marginRight: 0 },
    };

    if (
      process.env.REACT_APP_ENABLE_SECTION_PAYMENT_LINK === "YES" &&
      policies.includes(Policies.types.HANDY_LINK)
    ) {
      salesItem.items.push({
        id: ROUTES.PAYMENT_LINK.id,
        label: "Menu.PaymentLink",
        path: ROUTES.PAYMENT_LINK.path,
        icon: (
          <img
            src={paymentLinkIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="Handy Link"
          />
        ),
        enabled: true,
      });
    }
    if (
      process.env.REACT_APP_ENABLE_SECTION_PAYMENT_LINK === "YES" &&
      policies.includes(Policies.types.HANDY_QR)
    ) {
      salesItem.items.push({
        id: ROUTES.HANDY_QR.id,
        label: "Menu.HandyQr",
        path: ROUTES.HANDY_QR.path,
        icon: (
          <img
            src={qrIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 24 }}
            alt="Handy QR"
          />
        ),
        enabled: true,
      });
    }
    if (
      process.env.REACT_APP_ENABLE_SECTION_ITAU === "YES" &&
      policies.includes(Policies.types.ITAU)
    ) {
      salesItem.items.push({
        id: ROUTES.ITAU.id,
        label: "Menu.Itau",
        path: ROUTES.ITAU.path,
        icon: (
          <img
            src={ItauIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 22 }}
            alt="Financiación Itaú"
          />
        ),
        enabled: true,
      });
    }

    menu.push(salesItem);

    if (policies.includes(Policies.types.PROFILE_WRITE)) {
      menu.push({
        id: ROUTES.ECOMMERCE.id,
        label: "Menu.Ecommerce",
        icon: (
          <img
            src={EcommerceIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 25 }}
            alt="Ecommerce"
          />
        ),
        enabled: true,
      });
    }

    if (
      process.env.REACT_APP_ENABLE_SECTION_SUBSCRIPTIONS === "YES" &&
      policies.includes(Policies.types.SUBSCRIBER)
    ) {
      menu.push({
        id: ROUTES.MANAGE_PROVIDERS_PAYMENTS.id,
        label: "Menu.Subscriptions",
        path: ROUTES.MANAGE_PROVIDERS_PAYMENTS.path,
        icon: (
          <img
            src={SubscriptonsIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="Pago a proveedores"
          />
        ),
        enabled: true,
      });
    }

    const providersItem = {
      type: "section",
      title: "Menu.Sections.Providers",
      items: [],
      icon: (
        <img
          src={Providerscon}
          className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
          style={{ width: 27 }}
          alt="Cobranzas"
        />
      ),
      iconWrapperStyle: { justifyContent: "flex-start", marginRight: 0 },
    };

    if (
      process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES" &&
      policies.includes(Policies.types.PROVIDER)
    ) {
      providersItem.items.push({
        id: ROUTES.MANAGE_PROVIDERS.id,
        label: "Menu.Providers",
        path: ROUTES.MANAGE_PROVIDERS.path,
        icon: (
          <img
            src={Providerscon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="Cobranzas"
          />
        ),
        enabled: true,
      });
    }

    if (
      process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS === "YES"
    ) {
      providersItem.items.push({
        id: ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id,
        label: "Menu.ProvidersSubscriptions",
        path: ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.path,
        icon: (
          <img
            src={Providerscon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="Cobranzas"
          />
        ),
        enabled: true,
      });
    }

    if (providersItem.items.length > 0) {
      menu.push(providersItem);
    }

    if (
      process.env.REACT_APP_ENABLE_SECTION_LOANS === "YES" &&
      policies.includes(Policies.types.LOANS)
    ) {
      menu.push({
        id: ROUTES.LOANS.id,
        label: "Menu.Loans",
        path: ROUTES.LOANS.path,
        icon: (
          <img src={loansIcon} className={classes.listItemIcon} alt="Loans" />
        ),
        enabled: true,
      });
    }

    const historyItem = {
      type: "section",
      title: "Menu.Sections.History",
      items: [],
      icon: (
        <img
          src={historyIcon}
          className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
          style={{ width: 27 }}
          alt="Consultas e historicos"
        />
      ),
      iconWrapperStyle: { justifyContent: "flex-start", marginRight: 0 },
    };

    if (
      process.env.REACT_APP_ENABLE_SECTION_REVERSALS === "YES" &&
      policies.includes(Policies.types.REVERSALS)
    ) {
      historyItem.items.push({
        id: ROUTES.REVERSALS.id,
        label: "Menu.Reversals",
        path: ROUTES.REVERSALS.path,
        icon: (
          <img
            src={reversalsIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="Reversals"
          />
        ),
        enabled: true,
      });
    }

    if (
      process.env.REACT_APP_ENABLE_SECTION_YOUR_MONEY === "YES" &&
      policies.includes(Policies.types.YOUR_MONEY)
    ) {
      historyItem.items.push({
        id: ROUTES.YOUR_MONEY.id,
        label: "Menu.YourSales",
        path: ROUTES.YOUR_MONEY.path,
        icon: (
          <img
            src={yourMoneyIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 24 }}
            alt="Your Money"
          />
        ),
        enabled: true,
      });
    }

    if (
      process.env.REACT_APP_ENABLE_SECTION_HISTORY === "YES" &&
      policies.includes(Policies.types.HISTORY)
    ) {
      historyItem.items.push({
        id: ROUTES.HISTORY.id,
        label: "Menu.History",
        path: ROUTES.HISTORY.path,
        icon: (
          <img
            src={historyIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="History"
          />
        ),
        enabled: true,
      });
    }

    if (
      process.env.REACT_APP_ENABLE_SECTION_VOUCHERS === "YES" &&
      policies.includes(Policies.types.VOUCHERS)
    ) {
      historyItem.items.push({
        id: ROUTES.VOUCHERS.id,
        label: "Menu.Tickets",
        path: ROUTES.VOUCHERS.path,
        icon: (
          <img
            src={vouchersIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="Tickets"
          />
        ),
        enabled: true,
      });
    }

    if (
      process.env.REACT_APP_ENABLE_SECTION_MOBILE_BALANCE === "YES" &&
      policies.includes(Policies.types.MOBILE_BALANCE)
    ) {
      historyItem.items.push({
        id: ROUTES.MOBILE_BALANCE.id,
        label: "Menu.MobileBalance",
        path: ROUTES.MOBILE_BALANCE.path,
        icon: (
          <img
            src={mobileBalanceIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 16 }}
            alt="Mobile Balance"
          />
        ),
        enabled: true,
      });
    }

    menu.push(historyItem);

    const settingsItem = {
      type: "section",
      title: "Menu.Sections.Settings",
      items: [],
      icon: (
        <img
          src={profileIcon}
          className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
          style={{ width: 27 }}
          alt="Profile"
        />
      ),
      iconWrapperStyle: { justifyContent: "flex-start", marginRight: 0 },
    };

    if (
      process.env.REACT_APP_ENABLE_SECTION_PROFILE === "YES" &&
      policies.includes(Policies.types.PROFILE)
    ) {
      const canChangeSensitiveData =
        policies && policies.includes(Policies.types.SENSITIVE_DATA);
      if (canChangeSensitiveData) {
        settingsItem.items.push({
          id: ROUTES.MANAGE_PAYMENT_METHODS.id,
          label: "Menu.ProfilePaymentMethods",
          path: ROUTES.MANAGE_PAYMENT_METHODS.path,
          icon: (
            <img
              src={manageAccountsIcon}
              className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
              style={{ width: 27 }}
              alt="Manage Accounts"
            />
          ),
          enabled: true,
        });
        if (
          process.env.REACT_APP_ENABLE_SECTION_MANAGE_POS_REQUESTS === "YES"
        ) {
          settingsItem.items.push({
            id: ROUTES.MANAGE_POS_REQUESTS.id,
            label: "Menu.ProfileManagePosRequests",
            path: ROUTES.MANAGE_POS_REQUESTS.path,
            icon: (
              <img
                src={managePosIcon}
                className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
                style={{ width: 27 }}
                alt="Manage Pos"
              />
            ),
            enabled: true,
          });
        }
      }

      if (
        process.env.REACT_APP_ENABLE_SECTION_MANAGE_USERS === "YES" &&
        policies.includes(Policies.types.MANAGE_USERS)
      ) {
        settingsItem.items.push({
          id: ROUTES.MANAGE_USERS.id,
          label: "Menu.ManageUsers",
          path: ROUTES.MANAGE_USERS.path,
          icon: (
            <img
              src={manageUsersIcon}
              className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
              style={{ width: 27 }}
              alt="Profile"
            />
          ),
          enabled: true,
        });
      }

      settingsItem.items.push({
        id: ROUTES.PROFILE.id,
        label: "Menu.Profile",
        path: ROUTES.PROFILE.path,
        icon: (
          <img
            src={profileIcon}
            className={clsx(classes.listItemIcon, classes.listItemIconSvg)}
            style={{ width: 27 }}
            alt="Profile"
          />
        ),
        enabled: true,
      });
    }

    menu.push(settingsItem);

    return menu.filter(
      (item) =>
        (item.items && item.items.length > 0) ||
        typeof item.items === "undefined"
    );
  };

  const checkForActiveServices = (menu) => {
    const routes = [];

    if (process.env.REACT_APP_ENABLE_SECTION_MOBILE_BALANCE === "YES" && policies.includes(Policies.types.MOBILE_BALANCE)) {
      routes.push(ROUTES.MOBILE_BALANCE.id);
    }
    if (process.env.REACT_APP_ENABLE_SECTION_ITAU === "YES" && policies.includes(Policies.types.ITAU)) {
      routes.push(ROUTES.ITAU.id);
    }
    if (process.env.REACT_APP_ENABLE_SECTION_PROVIDERS === "YES") {
      routes.push(ROUTES.MANAGE_PROVIDERS.id);
    }
    if (process.env.REACT_APP_ENABLE_SECTION_PROVIDERS_SUBSCRIPTIONS === "YES") {
      routes.push(ROUTES.MANAGE_PROVIDERS_SUBSCRIPTIONS.id);
    }
    if (policies.includes(Policies.types.PROFILE_WRITE)) {
      routes.push(ROUTES.ECOMMERCE.id);
    }

    dispatch(
      businessActions.checkForActiveServices(routes, (statuses) => {
        menu.forEach((route) => {
          if (route.type === "section") {
            route.items.forEach((route) => {
              if (routes.includes(route.id)) {
                route.enabled = statuses[route.id];
              }
            });
            route.items = route.items.filter((i) => i.enabled);
          } else if (routes.includes(route.id)) {
            if (route.id === ROUTES.ECOMMERCE.id) {
              route.enabled = statuses[route.id]?.length > 0;
              route.customAction = () => {
                dispatch(userActions.getToken(token => {
                  window.open(`${statuses[route.id]}?token=${token}`, "_blank");
                }));
              }
            } else {
              route.enabled = statuses[route.id];
            }
          }
        });
        menu = menu.filter((section) =>
          section.type !== "section"
            ? section.enabled
            : section.items?.length > 0
        );
        setRoutesSections(menu);
      })
    );
  };

  const _goToPage = (e, route) => {
    e.preventDefault();

    if (typeof route.customAction === 'function') {
      route.customAction();
      return;
    }

    if (route.path) {
      dispatch(push(route.path));
    }

    dispatch(commonActions.setSideMenuOpen(false));
  };

  const _goToPrivacyPolicy = () => {
    window.open(process.env.REACT_APP_PRIVACY_POLICY, "_blank");
  };

  const _handleSideMenuClose = () => {
    dispatch(commonActions.setSideMenuOpen(false));
  };

  const hasActiveSection = (sectionItems) => {
    let hasActiveSection = false;

    sectionItems.forEach((section) => {
      if (section.id === activePage) {
        hasActiveSection = true;
      }
    });

    return hasActiveSection;
  };

  const renderIcon = (item) => {
    return (
      <div
        style={item.iconWrapperStyle || null}
        className={classes.listItemIconWrapper}
      >
        {item.icon}
      </div>
    );
  };

  return (
    <Drawer
      className={classes.sideMenu}
      classes={{
        paper: clsx(
          classes.sideMenuPaper,
          withBanner ? classes.withBanner : null,
          withBoxBanner ? classes.withBoxBanner : null,
          withBoxBannerMd ? classes.withBoxBannerMd : null
        ),
      }}
      open={sideMenuOpen}
      onClose={_handleSideMenuClose}
      anchor="left"
      variant={isSmallScreen ? "temporary" : "permanent"}
    >
      <Paper elevation={0} className={classes.sideMenuToolbarWrapper}>
        <Toolbar className={classes.sideMenuToolbar}>
          <img src={appLogo} className={classes.appLogo} alt="App Logo" />
        </Toolbar>
      </Paper>
      <List className={classes.list}>
        {routesSections.map((section, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            {sectionIndex !== 0 ? (
              <div className={classes.listItemSeparator} />
            ) : null}
            {section.type === "section" ? (
              <Accordion
                className={classes.listSectionItem}
                defaultExpanded={hasActiveSection(section.items)}
              >
                <AccordionSummary
                  className={clsx(
                    classes.listSectionItemTitle,
                    hasActiveSection(section.items)
                      ? classes.listSectionItemTitleSelected
                      : null
                  )}
                  expandIcon={<ExpandMoreIcon />}
                >
                  {hasActiveSection(section.items) ? (
                    <div className={classes.listItemSelectedIndicator} />
                  ) : null}
                  {renderIcon(section)}
                  <ListItemText
                    primary={i18n.get(section.title)}
                    className={classes.listItemText}
                  />
                </AccordionSummary>
                <AccordionDetails
                  classes={{ root: classes.listSectionItemDetails }}
                >
                  <List className={classes.list}>
                    {section.items.map((item, itemIndex) => (
                      <React.Fragment key={itemIndex}>
                        {itemIndex !== 0 ? (
                          <div
                            className={clsx(
                              classes.listItemSeparator,
                              classes.listItemInnerSeparator
                            )}
                          />
                        ) : null}
                        <ListItem
                          button
                          selected={item.id === activePage}
                          onClick={(e) => _goToPage(e, item)}
                          className={
                            item.id === activePage
                              ? clsx(classes.listItem, classes.listItemSelected)
                              : classes.listItem
                          }
                        >
                          {renderIcon(item)}
                          <ListItemText
                            primary={i18n.get(item.label)}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ) : (
              <ListItem
                button
                selected={section.id === activePage}
                onClick={(e) => _goToPage(e, section)}
                className={
                  section.id === activePage
                    ? clsx(classes.listItem, classes.listItemSelected)
                    : classes.listItem
                }
              >
                {section.id === activePage ? (
                  <div className={classes.listItemSelectedIndicator} />
                ) : null}
                {renderIcon(section)}
                <ListItemText
                  primary={i18n.get(section.label)}
                  className={classes.listItemText}
                />
              </ListItem>
            )}
          </React.Fragment>
        ))}
        <div className={classes.listItemSeparator} />
        <Link
          component="button"
          className={classes.listLinkItem}
          onClick={_goToPrivacyPolicy}
        >
          {i18n.get("Menu.PrivacyPolicy")}
        </Link>
      </List>
    </Drawer>
  );
};

export default SideMenu;
