import React, { useEffect, useState } from 'react';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { Language } from '../../utils';
import makeClasses from './styles';
import { useSelector } from 'react-redux';

export const CUSTOM_FILTER = 6;

export function getDateRangeForFilter(filter, customFilter) {
  const dateRange = {
    startDate: null,
    endDate: null,
  };

  if (filter === 0) {
    dateRange.startDate = moment().startOf('day');
    dateRange.endDate = moment().endOf('day');
  } else if (filter === 1) {
    dateRange.startDate = moment().subtract(1, 'day').startOf('day');
    dateRange.endDate = moment().subtract(1, 'day').endOf('day');
  } else if (filter === 2) {
    dateRange.startDate = moment().subtract(7, 'day').startOf('day');
    dateRange.endDate = moment().endOf('day');
  } else if (filter === 3) {
    dateRange.startDate = moment().subtract(15, 'day').startOf('day');
    dateRange.endDate = moment().endOf('day');
  } else if (filter === 4) {
    dateRange.startDate = moment().startOf('month');
    dateRange.endDate = moment().endOf('month');
  } else if (filter === 5) {
    dateRange.startDate = moment().subtract(1, 'month').startOf('month');
    dateRange.endDate = moment().subtract(1, 'month').endOf('month');
  } else if (customFilter) {
    if (moment(customFilter.startDate).isSame(moment(customFilter.endDate))) {
      dateRange.startDate = moment(customFilter.startDate, 'YYYY-MM-DD').startOf('day');
      dateRange.endDate = moment(customFilter.endDate, 'YYYY-MM-DD').startOf('day');
    } else {
      dateRange.startDate = moment(customFilter.startDate, 'YYYY-MM-DD').startOf('day');
      dateRange.endDate = moment(customFilter.endDate, 'YYYY-MM-DD').endOf('day');
    }
  }

  return dateRange;
}

const FiltersDateSelector = ({ selectedFilter, startDate = null, endDate = null, onFilterSelected, align, maxDate = null }) => {
  const classes = makeClasses();

  const [filterOptions, setFilterOptions] = useState([]);
  const [isCustomFilter, setIsCustomFilter] = useState(startDate ? true : false);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate || new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [canChangeStartRange, setCanChangeStartRange] = useState(false);
  const { languageTexts } = useSelector((state) => ({
    languageTexts: state.language.texts || {},
  }));
  const i18n = Language(languageTexts);

  const hasFilterOptions = filterOptions.length > 0;

  useEffect(() => {
    setFilterOptions([
      {
        id: 0,
        label: 'TimeFilter.Today',
      },
      {
        id: 1,
        label: 'TimeFilter.Yesterday',
      },
      {
        id: 2,
        label: 'TimeFilter.LastSevenDays',
      },
      {
        id: 3,
        label: 'TimeFilter.LastFifteenDays',
      },
      {
        id: 4,
        label: 'TimeFilter.ThisMonth',
      },
      {
        id: 5,
        label: 'TimeFilter.LastMonth',
      },
      {
        id: CUSTOM_FILTER,
        label: 'TimeFilter.Custom',
      },
    ]);
  }, []);

  const _handleOnChange = (e) => {
    e.stopPropagation();

    if (e.target.value === CUSTOM_FILTER) {
      setIsCustomFilter(true);
      setShowDatePicker(true);
      setCanChangeStartRange(true);
      return false;
    }

    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setIsCustomFilter(false);

    if (typeof onFilterSelected === 'function') {
      onFilterSelected(e.target.value);
    }
  };

  const _handleDateChange = (date) => {
    if (canChangeStartRange) {
      setSelectedStartDate(date);
      setCanChangeStartRange(false);
    } else {
      if (Math.abs(moment(selectedStartDate).diff(moment(date), 'months')) > 3) {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        alert(i18n.get('TimeFilter.CustomFilterError'));
        return false;
      }

      setSelectedEndDate(date);
      setShowDatePicker(false);

      if (typeof onFilterSelected === 'function') {
        onFilterSelected(
          CUSTOM_FILTER,
          moment(selectedStartDate).format('YYYY-MM-DD'),
          moment(date).format('YYYY-MM-DD')
        );
      }
    }
  };

  const _handleOnCustomFilterClick = () => {
    setIsCustomFilter(true);
    setShowDatePicker(true);
    setCanChangeStartRange(true);
  };

  const rederDatePickerDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    const isInRange = moment(day).isBetween(moment(selectedStartDate), moment(selectedEndDate));
    const isStartDate = moment(day).isSame(moment(selectedStartDate), 'day');
    const isEndDate = moment(day).isSame(moment(selectedEndDate), 'day');

    return (
      <div
        style={{
          background: (isStartDate || isInRange) && selectedEndDate ? 'rgba(0,0,0,0.1)' : null,
          color: 'white',
          borderTopLeftRadius: isStartDate && selectedEndDate ? 30 : 0,
          borderBottomLeftRadius: isStartDate && selectedEndDate ? 30 : 0,
          borderTopRightRadius: isEndDate ? 30 : 0,
          borderBottomRightRadius: isEndDate ? 30 : 0,
        }}
      >
        {dayComponent}
      </div>
    );
  };

  const renderFilter = () => (
    <div className={classes.container}>
      <Select
        value={hasFilterOptions && !isCustomFilter ? selectedFilter : isCustomFilter ? CUSTOM_FILTER : ''}
        onChange={_handleOnChange}
        className={classes.selectContainer}
      >
        {filterOptions.map((filter, index) => (
          <MenuItem
            key={index}
            value={filter.id}
            onClick={filter.id === CUSTOM_FILTER ? _handleOnCustomFilterClick : null}
            className={classes.selectOption}
          >
            {filter.id === CUSTOM_FILTER && selectedStartDate && selectedEndDate
              ? `${moment(selectedStartDate).format('LL')} - ${moment(selectedEndDate).format('LL')}`
              : i18n.get(filter.label)}
          </MenuItem>
        ))}
      </Select>
      <div className={classes.datePickerWrapper}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
          <DatePicker
            renderInput={(props) => <TextField {...props} />}
            onChange={_handleDateChange}
            disableToolbar
            variant="inline"
            open={showDatePicker}
            renderDay={rederDatePickerDay}
            value={selectedStartDate}
            PopoverProps={{ onClose: () => setShowDatePicker(false) }}
            className={classes.datePicker}
            maxDate={maxDate?.toDate() || undefined}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );

  return <div className={align === 'right' ? classes.alignRight : null}>{renderFilter()}</div>;
};

export default FiltersDateSelector;
